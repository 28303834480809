import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";

import ChatService from "../../service/ChatService";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./components/chat-header";
import ChatInput from "./components/chat-input";
import BotMessage from "./components/bot-message";
import UserMessage from "./components/user-message";
import useProjectStore from "../../stores/projectStore";


const Chat = () => {
  const { chatId } = useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [context, setContext] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedMessage, setEditedMessage] = useState("");
  const { selectedProject } = useProjectStore();
  const chatEndRef = useRef(null);
  const [feedbackPanel, setFeedbackPanel] = useState({
    isOpen: false,
    type: null,
    messageId: null,
    selectedFeedback: null,
  });

  useEffect(() => {
    // Fetch chat history based on chatId
    const fetchChatHistory = async () => {
      //   try {
      //     const response = await ChatService.getChatHistory(chatId);
      //     setChatHistory(response.history);
      //     setContext(response.context);
      //   } catch (error) {
      //     console.error("Error fetching chat history:", error);
      //     setChatHistory([
      //       { id: uuidv4(), text: "Error loading chat history.", fromUser: false },
      //     ]);
      //   }
    };

    if (chatId) {
      fetchChatHistory();
    } else {
      const welcomeMessage =
        "Welcome to TIA Data Talk! How can I assist you today?";
      setChatHistory([{ id: uuidv4(), text: welcomeMessage, fromUser: false }]);
    }
  }, [chatId]);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Copied",
          detail: "Text copied to clipboard",
          life: 3000,
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to copy text",
          life: 3000,
        });
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "text/plain" ||
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setSelectedFile(file);
      console.log(`File accepted: ${file.name}`);
    } else {
      alert("Only .txt, .doc, and .docx files are allowed.");
      setSelectedFile(null);
    }
  };

  const handleSend = async () => {
    if (message.trim() || selectedFile) {
      if (message.trim()) {
        await sendQuestionToAPI(message);
        setMessage("");
      }

      if (selectedFile) {
        await handleFileUpload(selectedFile);
        setSelectedFile(null);
      }
    }
  };

  const handleFileUpload = async (file) => {
    try {
      // Parse the file to extract questions
      const questionsFromFile = await parseFile(file);

      if (questionsFromFile.length > 0) {
        // Update context with file-uploaded questions
        setContext((prevContext) => [...prevContext, ...questionsFromFile]);

        // Send each question from the file to the API
        for (let question of questionsFromFile) {
          await sendQuestionToAPI(question);
        }
      } else {
        setChatHistory((prevHistory) => [
          ...prevHistory,
          {
            id: uuidv4(),
            text: "No valid questions found in the file.",
            fromUser: false,
          },
        ]);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { id: uuidv4(), text: "Error uploading file.", fromUser: false },
      ]);
    }
  };

  const parseFile = async (file) => {
    const text = await readFileContent(file);
    console.log("File content:", text);

    // Extract questions from the file content
    const questions = extractQuestions(text);
    return questions;
  };

  const extractQuestions = (text) => {
    const lines = text.split("\n");
    return lines.filter((line) => line.trim().endsWith("?"));
  };

  const formatMessage = (text) => {
    // Replace \n with two spaces and a newline for markdown line breaks
    return text.replace(/\n/g, "  \n");
  };

  const sendQuestionToAPI = async (question) => {
    const userMessage = { id: uuidv4(), text: question, fromUser: true };
    setChatHistory((prevHistory) => [...prevHistory, userMessage]);
    setContext((prevContext) => {
      console.log({ prevContext });
      return [...prevContext, question];
    });

    setIsTyping(true);

    let currentChatId = chatId;
    if (!currentChatId) {
      currentChatId = uuidv4();
      navigate(`/portal/chat/${currentChatId}`, { replace: true });
    }

    const payload = {
      id: uuidv4(),
      chatId: currentChatId,
      user_query: question,
      context: context,
      modelname: "GPT",
      project_id: selectedProject.project_id,
    };

    try {
      const response = await ChatService.getAnswer(payload);
      const { response: apiResponse, references, cscore } = response;

      let botMessage = {
        id: uuidv4(),
        text: formatMessage(apiResponse),
        fromUser: false,
        cscore: cscore,
        references: references,
      };
      setChatHistory((prevHistory) => [...prevHistory, botMessage]);
      setContext((prevContext) => [...prevContext, apiResponse]);
    } catch (error) {
      setChatHistory((prevHistory) => [
        ...prevHistory,
        {
          id: uuidv4(),
          text: "Error retrieving response from API.",
          fromUser: false,
        },
      ]);
      console.error("Error retrieving response from API:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const handleExport = () => {
    const exportData = chatHistory
      .map(
        (entry, index) => `${entry.fromUser ? "User" : "Bot"}: ${entry.text}`
      )
      .join("\n");
    const blob = new Blob([exportData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "chat_history.txt";
    link.click();
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedMessage(chatHistory[index].text);
  };

  const handleSaveEdit = async () => {
    setChatHistory((prevHistory) =>
      prevHistory.map((entry, index) =>
        index === editingIndex ? { ...entry, text: editedMessage } : entry
      )
    );
    setEditingIndex(null);
    setEditedMessage("");

    const editedChatHistory = chatHistory.slice(0, editingIndex);
    setChatHistory(editedChatHistory);

    await sendQuestionToAPI(editedMessage);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditedMessage("");
  };

  const handleFeedback = (messageId, type) => {
    if (feedbackPanel.messageId === messageId && feedbackPanel.type === type) {
      closeFeedbackPanel();
      return;
    }

    setFeedbackPanel({
      isOpen: true,
      type,
      messageId,
      selectedFeedback: null,
    });
  };
  const handleFeedbackSelection = (feedback) => {
    setFeedbackPanel((prev) => ({
      ...prev,
      isOpen: false,
      selectedFeedback: feedback,
    }));
  };
  const closeFeedbackPanel = () => {
    setFeedbackPanel({
      isOpen: false,
      type: null,
      messageId: null,
      selectedFeedback: null,
    });
  };

  return (
    <div className="flex flex-col w-full h-full rounded-xl shadow-lg bg-white overflow-hidden">
      <Toast ref={toast} />
      <ChatHeader onExport={handleExport} />
      <div className="flex flex-col max-w-4xl w-full mx-auto h-full overflow-hidden">
        <div
          className="flex-1 overflow-y-auto overflow-x-hidden p-4 flex flex-col gap-2 scrollbar-gutter-stable"
          style={{
            scrollbarGutter: "stable",
            paddingRight: "calc(1rem + 2px)",
          }}
        >
          {chatHistory.map((entry, index) =>
            entry.fromUser ? (
              <UserMessage
                key={entry.id}
                entry={entry}
                index={index}
                editingIndex={editingIndex}
                editedMessage={editedMessage}
                onEdit={handleEdit}
                onSaveEdit={handleSaveEdit}
                onCancelEdit={handleCancelEdit}
                setEditedMessage={setEditedMessage}
              />
            ) : (
              <BotMessage
                key={entry.id}
                entry={entry}
                feedbackPanel={feedbackPanel}
                onCopy={handleCopy}
                onFeedback={handleFeedback}
                onFeedbackSelection={handleFeedbackSelection}
                onCloseFeedback={closeFeedbackPanel}
              />
            )
          )}

          {isTyping && (
            <div className="flex items-center italic text-[#6c757d] my-2.5 text-sm">
              <div className="inline-block w-2 h-2 mx-0.5 bg-[#6c757d] rounded-full animate-typing"></div>
              <div className="inline-block w-2 h-2 mx-0.5 bg-[#6c757d] rounded-full animate-typing animation-delay-300"></div>
              <div className="inline-block w-2 h-2 mx-0.5 bg-[#6c757d] rounded-full animate-typing animation-delay-600"></div>
            </div>
          )}
          <div ref={chatEndRef} />
        </div>
        <ChatInput
          message={message}
          setMessage={setMessage}
          onSend={handleSend}
          onFileChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default Chat;

import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import useNotificationStore from "../../../stores/notificationStore";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import useWorkspaceStore from "../../../stores/workspaceStore";

export const NotificationCenter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const {
    isLoading,
    notifications,
    fetchNotifications,
    markNotificationRead,
    markNotificationArchived,
    markAllNotificationsRead,
  } = useNotificationStore();

  useOnClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    // Initial fetch
    fetchNotifications();

    // Set up interval to fetch every minute
    const interval = setInterval(() => {
      fetchNotifications();
    }, 60000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [fetchNotifications]);

  const unreadCount = notifications.filter((n) => n.status === "unread").length;

  return (
    <div ref={ref} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-1.5 bg-transparent text-gray-500 hover:bg-gray-50 rounded-md transition-colors duration-150"
      >
        <i className="pi pi-bell text-[14px]" />
        {unreadCount > 0 && (
          <span className="absolute -top-0.5 -right-0.5 bg-blue-500 text-white text-[10px] w-4 h-4 flex items-center justify-center rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      <AnimatePresence>
        {isOpen && (
          <NotificationPanel
            notifications={notifications}
            isLoading={isLoading}
            onClose={() => setIsOpen(false)}
            onMarkRead={markNotificationRead}
            onMarkArchived={markNotificationArchived}
            onMarkAllRead={markAllNotificationsRead}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const NotificationPanel = ({
  notifications,
  isLoading,
  onClose,
  onMarkRead,
  onMarkArchived,
  onMarkAllRead,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 10 }}
      className="absolute top-full right-full mb-2 w-80 bg-white rounded-lg shadow-lg border border-gray-200 overflow-hidden z-50"
    >
      <div className="p-3 border-b border-gray-100 border-solid flex items-center justify-between">
        <p className="text-sm font-medium">Notifications</p>
        <button
          onClick={onMarkAllRead}
          className="text-xs bg-transparent text-gray-500 hover:text-gray-700"
        >
          Mark all as read
        </button>
      </div>

      <div className="max-h-96 overflow-y-auto">
        {isLoading ? (
          <div className="divide-y divide-gray-100">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="p-3 flex items-start gap-3">
                <div className="mt-0.5 w-8 h-8 rounded-full bg-gray-200 animate-pulse" />
                <div className="flex-1">
                  <div className="h-4 bg-gray-200 rounded animate-pulse mb-2" />
                  <div className="h-3 bg-gray-200 rounded animate-pulse w-24" />
                </div>
              </div>
            ))}
          </div>
        ) : notifications.length === 0 ? (
          <div className="p-4 text-center text-sm text-gray-500">
            No notifications
          </div>
        ) : (
          <div className="divide-y divide-gray-100">
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.notification_id}
                notification={notification}
                onMarkRead={onMarkRead}
                onMarkArchived={onMarkArchived}
              />
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

const NotificationItem = ({ notification, onMarkRead, onMarkArchived }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { respondToInvite } = useWorkspaceStore();

  const getIcon = () => {
    switch (notification.type) {
      case "workspace_invitation":
        return "pi-users";
      case "mention":
        return "pi-at";
      case "comment":
        return "pi-comment";
      default:
        return "pi-bell";
    }
  };

  const handleInviteResponse = async (response) => {
    setIsLoading(true);
    try {
      await respondToInvite(notification.workspace_id, response);
      await onMarkRead(notification.notification_id);
    } catch (error) {
      console.error(`Failed to ${response} workspace invitation:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`p-3 flex items-start gap-3 ${
        notification.status === "unread" ? "bg-blue-50" : "bg-white"
      } hover:bg-gray-50 transition-colors duration-150`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`mt-0.5 w-8 h-8 rounded-full flex items-center justify-center ${
          notification.status === "unread" ? "bg-blue-100" : "bg-gray-100"
        }`}
      >
        <i
          className={`pi ${getIcon()} ${
            notification.status === "unread" ? "text-blue-500" : "text-gray-500"
          }`}
        />
      </div>

      <div className="flex-1 min-w-0">
        <p className="text-sm text-gray-900">{notification.message}</p>
        <p className="text-xs text-gray-500 mt-1">
          {format(new Date(notification.created_at), "MMM d, h:mm a")}
        </p>

        {notification.type === "workspace_invitation" &&
          notification.status === "unread" && (
            <div className="mt-2 flex gap-2">
              <button
                onClick={() => handleInviteResponse("accept")}
                disabled={isLoading}
                className="px-3 py-1 text-xs font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors duration-150"
              >
                Accept
              </button>
              <button
                onClick={() => handleInviteResponse("reject")}
                disabled={isLoading}
                className="px-3 py-1 text-xs font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors duration-150"
              >
                Decline
              </button>
            </div>
          )}
      </div>

      <AnimatePresence>
        {isHovered && !notification.type === "workspace_invitation" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex gap-1"
          >
            {notification.status === "unread" && (
              <button
                onClick={() => onMarkRead(notification.notification_id)}
                className="p-1 text-gray-400 hover:text-gray-600 rounded"
                title="Mark as read"
              >
                <i className="pi pi-check text-[12px]" />
              </button>
            )}
            <button
              onClick={() => onMarkArchived(notification.notification_id)}
              className="p-1 text-gray-400 hover:text-gray-600 rounded"
              title="Archive"
            >
              <i className="pi pi-trash text-[12px]" />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

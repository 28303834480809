import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import {
  AiOutlinePushpin,
  AiOutlineLink,
  AiOutlineCopy,
  AiOutlineCloud,
  AiOutlineDelete,
  AiOutlineSetting,
  AiOutlineShareAlt,
  AiOutlineFolder,
  AiOutlineClockCircle,
} from "react-icons/ai";
import { FaEllipsisH } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import ReportService from "../../service/ReportService";
import { LuChevronRight } from "react-icons/lu";
import useProjectStore from "../../stores/projectStore";
import useFolderStore from "../../stores/folderStore";
import { REPORT_STATUS_LABELS } from "../../utils/constants";

const ReportItem = ({
  report,
  isActive,
  reports,
  onUpdateReports,
  onShowToast,
}) => {
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const { folders, createFolder } = useFolderStore();
  const navigate = useNavigate();

  const openReport = () => {
    navigate(`/portal/reports/${report.report_id}`);
  };
  const handleStatusChange = async (newStatus) => {
    const previousReports = reports;
    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? { ...r, status: newStatus, updatedAt: new Date().toISOString() }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, { status: newStatus });
      onShowToast(
        "success",
        "Status Updated",
        `Report status changed to ${REPORT_STATUS_LABELS[newStatus].text}`
      );
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Update Failed", "Failed to update report status");
      console.error("Error updating report status:", error);
    }
  };
  const handlePin = async () => {
    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? {
            ...r,
            isPinned: !r.isPinned,
            updatedAt: new Date().toISOString(),
          }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, {
        isPinned: !report.isPinned,
      });
      const action = report.isPinned ? "unpinned" : "pinned";
      onShowToast("success", `Report ${action}`, `Report has been ${action}`);
    } catch (error) {
      onUpdateReports(reports);
      onShowToast(
        "error",
        "Update Failed",
        "Failed to update report pin status"
      );
      console.error("Error updating report pin status:", error);
    }
  };

  const handleDelete = async () => {
    const previousReports = reports;
    const updatedReports = reports.filter(
      (r) => r.report_id !== report.report_id
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.deleteReport(report.report_id);
      onShowToast("info", "Report Deleted", `Report has been deleted`);
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Delete Failed", "Failed to delete report");
      console.error("Error deleting report:", error);
    }
  };

  const handleDuplicate = async () => {
    const newReport = {
      ...report,
      report_id: Math.floor(Math.random() * 1000000),
      title: `${report.title} (Duplicate)`,
      name: `${report.name} (Duplicate)`,
      isPinned: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    onUpdateReports([...reports, newReport]);

    try {
      const createdReport = await ReportService.createReport({
        name: newReport.name,
        title: newReport.title,
        is_pinned: newReport.isPinned,
      });

      // Update with actual server data
      const finalReports = reports.concat({
        ...newReport,
        report_id: createdReport.report_id,
      });
      onUpdateReports(finalReports);

      onShowToast(
        "success",
        "Report Duplicated",
        `${report.name} has been duplicated`
      );
      navigate(`/portal/reports/${createdReport.report_id}`);
    } catch (error) {
      onUpdateReports(reports);
      onShowToast("error", "Duplication Failed", "Failed to duplicate report");
      console.error("Error duplicating report:", error);
    }
  };

  const handleReportNameChange = async (newName) => {
    const previousReports = reports;

    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? { ...r, name: newName, updatedAt: new Date().toISOString() }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, { name: newName });
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Update Failed", "Failed to update report name");
      console.error("Error updating report name:", error);
    }
  };

  const handleCopyLink = () => {
    const reportLink = `${window.location.origin}/portal/reports/${report.report_id}`;
    navigator.clipboard
      .writeText(reportLink)
      .then(() => {
        onShowToast(
          "success",
          "Link Copied",
          "Report link has been copied to clipboard"
        );
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
        onShowToast(
          "error",
          "Copy Failed",
          "Failed to copy report link to clipboard"
        );
      });
  };

  const handleCopyShareableLink = () => {
    const shareableLink = `${window.location.origin}/preview/report/${report.report_id}`;
    navigator.clipboard
      .writeText(shareableLink)
      .then(() => {
        onShowToast(
          "success",
          "Shareable Link Copied",
          "Shareable link has been copied to clipboard"
        );
      })
      .catch((err) => {
        console.error("Failed to copy shareable link: ", err);
        onShowToast(
          "error",
          "Copy Failed",
          "Failed to copy shareable link to clipboard"
        );
      });
  };

  const handleArchive = async () => {
    const previousReports = reports;
    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? { ...r, archived: true, updatedAt: new Date().toISOString() }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, { archived: true });
      onShowToast("success", "Report Archived", `Report has been archived`);
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Archive Failed", "Failed to archive report");
      console.error("Error archiving report:", error);
    }
  };

  const handleSettings = () => {
    navigate(`/portal/reports/${report.report_id}/settings`);
  };

  const handleMoveToFolder = async (folderId) => {
    const previousReports = reports;
    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? { ...r, folderId, updatedAt: new Date().toISOString() }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, {
        folder_id: folderId,
      });
      const selectedFolder = folders.find((f) => f.id === folderId);
      onShowToast(
        "success",
        "Report Moved",
        `Report moved to ${selectedFolder.name}`
      );
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Move Failed", "Failed to move report");
      console.error("Error moving report:", error);
    }
  };

  const { selectedProject } = useProjectStore();
  const handleCreateFolder = async () => {
    try {
      const folderName = newFolderName.trim();
      if (!folderName) return;

      const newFolder = await createFolder({
        name: folderName,
        project_id: selectedProject.project_id,
      });

      // Move report to the new folder
      await handleMoveToFolder(newFolder.id);

      setIsCreatingFolder(false);
      setNewFolderName("");
      onShowToast(
        "success",
        "Folder Created",
        `Folder "${folderName}" created`
      );
    } catch (error) {
      onShowToast("error", "Creation Failed", "Failed to create new folder");
      console.error("Error creating folder:", error);
    }
  };
  return (
    <>
      <li
        className={`cursor-pointer p-2 rounded text-sm group ${
          isActive
            ? "bg-gray-100 text-gray-800"
            : "text-gray-600 hover:bg-gray-100 hover:text-gray-800"
        }`}
      >
        <div className="flex items-center justify-between">
          <div onClick={openReport} className="flex items-center flex-grow">
            <span className="mr-2">{report.isPinned ? "📌" : "📄"}</span>
            <input
              type="text"
              value={report.name}
              onChange={(e) => handleReportNameChange(e.target.value)}
              className="bg-transparent border-none focus:outline-none"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <Menu as="div" className="relative inline-block text-left">
            <MenuButton className="invisible bg-transparent hover:bg-gray-200 rounded flex items-center group-hover:visible">
              <FaEllipsisH className="text-gray-600 hover:text-gray-800" />
            </MenuButton>
            <MenuItems className="absolute z-40 border border-solid border-gray-200 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-lg bg-white shadow-lg focus:outline-none">
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-white text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                    onClick={() => handlePin(report.report_id)}
                  >
                    <AiOutlinePushpin className="mr-2" />
                    {report.isPinned ? "Unpin" : "Pin"}
                  </button>
                )}
              </MenuItem>
              <Menu as="div" className="relative">
                <MenuButton as={Fragment}>
                  {({ focus }) => (
                    <button
                      className={`${
                        focus
                          ? "bg-gray-100/70 text-gray-900"
                          : "bg-white text-gray-700"
                      } group flex w-full items-center px-4 py-2 text-sm justify-between hover:bg-gray-100/70`}
                    >
                      <div className="flex items-center">
                        <AiOutlineClockCircle className="mr-2" />
                        Status
                      </div>
                      <div className="flex items-center">
                        <span
                          className={`mr-2 text-xs px-2 py-0.5 rounded-full ${
                            REPORT_STATUS_LABELS[report.status || "draft"].color
                          }`}
                        >
                          {REPORT_STATUS_LABELS[report.status || "draft"].text}
                        </span>
                        <LuChevronRight />
                      </div>
                    </button>
                  )}
                </MenuButton>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute z-50 border border-solid border-gray-200 left-full top-0 w-48 -ml-1 origin-top-left divide-y divide-gray-200 rounded-lg bg-white shadow-lg focus:outline-none">
                    {Object.entries(REPORT_STATUS_LABELS).map(
                      ([value, { text, color }]) => (
                        <MenuItem key={value}>
                          {({ focus }) => (
                            <button
                              className={`${
                                focus
                                  ? "bg-gray-100/70 text-gray-900"
                                  : "bg-white text-gray-700"
                              } group flex w-full items-center px-4 py-2 text-sm justify-between`}
                              onClick={() => handleStatusChange(value)}
                            >
                              <span>{text}</span>
                              <span
                                className={`text-xs px-2 py-0.5 rounded-full ${color}`}
                              >
                                {text}
                              </span>
                            </button>
                          )}
                        </MenuItem>
                      )
                    )}
                  </MenuItems>
                </Transition>
              </Menu>
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-white text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                    onClick={() => handleCopyLink(report.report_id)}
                  >
                    <AiOutlineLink className="mr-2" />
                    Copy Link
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-white text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                    onClick={() => handleCopyShareableLink(report.report_id)}
                  >
                    <AiOutlineShareAlt className="mr-2" />
                    Copy Shareable Link
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-white text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                    onClick={() => handleDuplicate(report.report_id)}
                  >
                    <AiOutlineCopy className="mr-2" />
                    Duplicate
                  </button>
                )}
              </MenuItem>
              <Menu as="div" className="relative">
                <MenuButton as={Fragment}>
                  {({ focus }) => (
                    <button
                      className={`${
                        focus
                          ? "bg-gray-100/70 text-gray-900"
                          : "bg-white text-gray-700"
                      } group flex w-full items-center px-4 py-2 text-sm justify-between hover:bg-gray-100/70`}
                    >
                      <div className="flex items-center">
                        <AiOutlineFolder className="mr-2" />
                        Move to Folder
                      </div>
                      <LuChevronRight />
                    </button>
                  )}
                </MenuButton>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute z-50 border border-solid border-gray-200 left-full top-0 w-48 -ml-1 origin-top-left divide-y divide-gray-200 rounded-lg bg-white shadow-lg focus:outline-none">
                    <MenuItem>
                      {({ focus }) =>
                        isCreatingFolder ? (
                          <input
                            type="text"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleCreateFolder();
                              } else if (e.key === "Escape") {
                                setIsCreatingFolder(false);
                                setNewFolderName("");
                              }
                            }}
                            onBlur={() => {
                              setIsCreatingFolder(false);
                              setNewFolderName("");
                            }}
                            placeholder="Press Enter to create"
                            className="w-full px-4 py-2 text-sm focus:outline-none"
                            autoFocus
                          />
                        ) : (
                          <button
                            className={`${
                              focus
                                ? "bg-gray-100/70 text-gray-900"
                                : "bg-white text-gray-700"
                            } group flex w-full items-center px-4 py-2 text-sm`}
                            onClick={(e) => {
                              e.preventDefault(); // Prevent menu from closing
                              e.stopPropagation(); // Prevent menu from closing
                              setIsCreatingFolder(true);
                            }}
                          >
                            + Create New Folder
                          </button>
                        )
                      }
                    </MenuItem>
                    {folders.map((folder) => (
                      <MenuItem key={folder.id}>
                        {({ focus }) => (
                          <button
                            className={`${
                              focus
                                ? "bg-gray-100/70 text-gray-900"
                                : "bg-white text-gray-700"
                            } group flex w-full items-center px-4 py-2 text-sm`}
                            onClick={() => handleMoveToFolder(folder.id)}
                          >
                            {folder.name}
                          </button>
                        )}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Transition>
              </Menu>
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-white text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                    onClick={() => handleArchive(report.report_id)}
                  >
                    <AiOutlineCloud className="mr-2" />
                    Archive
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-white text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                    onClick={() => handleSettings(report.report_id)}
                  >
                    <AiOutlineSetting className="mr-2" />
                    Settings
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`${
                      focus
                        ? "bg-gray-100/70 text-gray-900"
                        : "bg-white text-gray-700"
                    } group border-t border-solid border-0 flex w-full items-center px-4 py-2 text-sm hover:text-red-600`}
                    onClick={() => handleDelete(report.report_id)}
                  >
                    <AiOutlineDelete className="mr-2" />
                    Delete
                  </button>
                )}
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </li>
    </>
  );
};

export default ReportItem;

import { create } from "zustand";
import WorkspaceService from "../service/WorkspaceService";
import { persist, createJSONStorage } from "zustand/middleware";

const useProjectStore = create(
  persist(
    (set, get) => ({
      // State
      projects: [],
      selectedProject: null,
      isFetchingProjects: false,
      error: null,

      // Actions
      fetchProjects: async (workspaceId) => {
        if (!workspaceId) {
          set({ projects: [], selectedProject: null });
          return;
        }

        set({ isFetchingProjects: true, error: null });
        try {
          const data = await WorkspaceService.getWorkspaceProjects(workspaceId);
          set({ projects: data });

          // Select most recent project if none selected from the same workspace
          if (
            !get().selectedProject ||
            get().selectedProject.workspace_id !== workspaceId
          ) {
            if (data.length > 0) {
              const mostRecent = [...data].sort(
                (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
              )[0];
              set({ selectedProject: mostRecent });
            }
          }
        } catch (error) {
          set({
            error: error.message,
            projects: [],
            selectedProject: null,
          });
          console.error("Failed to fetch projects:", error);
        } finally {
          set({ isFetchingProjects: false });
        }
      },

      setSelectedProject: (project) => {
        set({ selectedProject: project });
      },

      createProject: async (workspaceId, projectData) => {
        set({ isLoading: true, error: null });
        try {
          const response = await WorkspaceService.createProject(
            workspaceId,
            projectData
          );
          const newProject = response.data;
          set((state) => ({
            projects: [...state.projects, newProject],
            selectedProject: newProject,
          }));
          return newProject;
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to create project:", error);
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      // Reset store
      reset: () => {
        set({
          projects: [],
          selectedProject: null,
          isLoading: false,
          error: null,
        });
      },
    }),
    {
      name: "project-storage", // unique name for this store
      storage: createJSONStorage(() => localStorage),
      // Only persist these specific fields
      partialize: (state) => ({
        projects: state.projects,
        selectedProject: state.selectedProject,
      }),
    }
  )
);

export default useProjectStore;

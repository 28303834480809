import axios from "axios";
import { WORKSPACE_API_BASE_URL } from "../utils/config";

const WorkspaceService = {
  createWorkspace: async (workspaceData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/create`,
        workspaceData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteWorkspaces: async (workspaceIds) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/delete`,
        {
          workspace_id: workspaceIds
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUserWorkspaces: async (userId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${WORKSPACE_API_BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createProject: async (workspaceId, projectData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/project`,
        projectData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceProjects: async (workspaceId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/projects`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },
  documentsUsed: async (paginationParams, workspaceId) => {
    const token = localStorage.getItem("token");
    try {
      const { page = 1, per_page = 10 } = paginationParams;
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/sources`,
        {
          params: {
            page,
            per_page,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  projectDocumentsUsed: async (paginationParams, projectId) => {
    const token = localStorage.getItem("token");
    try {
      const { page = 1, per_page = 10 } = paginationParams;
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/sources`,
        {
          params: {
            page,
            per_page,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addWorkspaceSource: async (fileOrPayload, workspaceId) => {
    const token = localStorage.getItem("token");
    try {
      // If fileOrPayload is an object, it's a link upload
      if (
        typeof fileOrPayload === "object" &&
        !(fileOrPayload instanceof File)
      ) {
        const response = await axios.post(
          `${WORKSPACE_API_BASE_URL}/${workspaceId}/source`,
          fileOrPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      }

      // Handle file upload
      const formData = new FormData();
      formData.append("file", fileOrPayload);

      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/source`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addProjectSource: async (fileOrPayload, projectId) => {
    const token = localStorage.getItem("token");
    try {
      // If fileOrPayload is an object, it's a link upload
      if (
        typeof fileOrPayload === "object" &&
        !(fileOrPayload instanceof File)
      ) {
        const response = await axios.post(
          `${WORKSPACE_API_BASE_URL}/project/${projectId}/source`,
          fileOrPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      }

      // Handle file upload
      const formData = new FormData();
      formData.append("file", fileOrPayload);

      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/source`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteWorkspaceSource: async (workspaceId, sourceId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/source/${sourceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteProjectSource: async (projectId, sourceId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/source/${sourceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceUsers: async (workspaceId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getWorkspaceUser: async (workspaceId, userId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createWorkspaceUser: async (workspaceId, userData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/user`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  inviteWorkspaceUser: async (workspaceId, inviteData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/invite`,
        inviteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteWorkspaceUser: async (workspaceId, userId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  respondToWorkspaceInvite: async (workspaceId, responseData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/${workspaceId}/invite/respond`,
        responseData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getProjectChats: async (projectId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/chats`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getChat: async (chatId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/chat/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteChat: async (chatId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${WORKSPACE_API_BASE_URL}/chat/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createChat: async (projectId, chatData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/project/${projectId}/chat`,
        chatData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateChat: async (chatId, chatData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${WORKSPACE_API_BASE_URL}/chat/${chatId}`,
        chatData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default WorkspaceService;

import { useEffect, useState, useRef } from "react";
import useWorkspaceStore from "../../../stores/workspaceStore";
import { Toast } from 'primereact/toast';

export const DeleteWorkspaceModal = ({ isOpen, onClose, workspaceIds = [], onSuccess = () => {} }) => {
    const [workspacesToDelete, setWorkspacesToDelete] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { workspaces, deleteWorkspaces } = useWorkspaceStore();
    const toast = useRef(null);

    useEffect(() => {
        if (!isOpen && workspacesToDelete.length > 0) {
            setWorkspacesToDelete([]);
            return;
        }

        if (!workspaceIds?.length || !workspaces?.length) return;

        const validWorkspaces = workspaces.filter(ws =>
            workspaceIds.includes(ws.workspace_id)
        );
        setWorkspacesToDelete(validWorkspaces);
    }, [workspaces, isOpen]);

    if (!isOpen || !workspacesToDelete.length) return null;

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            const workspaceIds = workspacesToDelete.map(ws => ws.workspace_id);
            await deleteWorkspaces(workspaceIds);
            onSuccess();
            onClose();
        } catch (error) {
            console.error("Failed to delete workspaces:", error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        } finally {
            setIsLoading(false);
        }
    };

    const workspaceNames = workspacesToDelete
        .map(ws => `${ws.name}`)
        .join(", ");

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <Toast ref={toast} position="bottom-right" />
            <div className="bg-white rounded-lg w-full max-w-md px-6 py-3">
                <div className="flex justify-between items-center">
                    <h3 className="text-[20px] leading-none font-semibold">Delete Workspaces</h3>
                    <button onClick={onClose} className="text-gray-400 p-1">
                        <i className="pi pi-times text-lg" />
                    </button>
                </div>

                <div>
                    {/* <div className="flex items-center gap-2 text-red-500 mb-2">
                        <i className="pi pi-exclamation-triangle text-lg" />
                        <span className="text-[15px]">Warning: This action cannot be undone</span>
                    </div> */}

                    <p className="text-[16px] text-gray-700 mb-8">
                        Are you sure you want to delete the following workspaces: {workspaceNames}?
                    </p>

                    <div className="flex justify-end gap-3 pb-3">
                        <button
                            onClick={onClose}
                            className="px-5 py-2 text-[15px] text-gray-600 hover:bg-gray-50 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleDelete}
                            disabled={isLoading}
                            className="px-5 py-2 text-[15px] text-white bg-red-500 hover:bg-red-600 rounded disabled:opacity-50"
                        >
                            {isLoading ? "Deleting..." : "Delete"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
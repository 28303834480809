import React, { useState, useRef, useEffect } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { FaUser, FaChevronDown } from "react-icons/fa";
import useWorkspaceStore from "../../stores/workspaceStore";

const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

const AssigneeSelector = ({ assignee, onAssigneeChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const { selectedWorkspace, workspaceUsers, fetchWorkspaceUsers } =
    useWorkspaceStore();

  useEffect(() => {
    if (selectedWorkspace?.workspace_id) {
      fetchWorkspaceUsers(selectedWorkspace.workspace_id);
    }
  }, [selectedWorkspace, fetchWorkspaceUsers]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const filteredUsers = workspaceUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAssigneeChange = (user) => {
    onAssigneeChange(user);
    setIsOpen(false);
  };

  // Find current assignee in workspace users
  const currentAssignee = assignee
    ? workspaceUsers.find((u) => u.user_id === assignee)
    : null;

  return (
    <Popover className="relative inline-block text-left mr-2">
      {({ open, close }) => (
        <>
          <PopoverButton
            className="flex items-center text-gray-700 bg-transparent hover:bg-gray-100 rounded p-1 text-sm"
            onClick={() => setIsOpen(!isOpen)}
          >
            {currentAssignee ? (
              <>
                {currentAssignee.avatar ? (
                  <img
                    src={currentAssignee.avatar}
                    alt={currentAssignee.name}
                    className="h-5 w-5 rounded-full mr-2"
                  />
                ) : (
                  <div className="h-5 w-5 rounded-full bg-gray-300 flex items-center justify-center text-xs font-bold mr-2">
                    {getInitials(currentAssignee.name)}
                  </div>
                )}
                <span>{currentAssignee.name}</span>
              </>
            ) : (
              <>
                <FaUser className="h-4 w-4 mr-2" />
                <span>Unassigned</span>
              </>
            )}
            <FaChevronDown className="h-3 w-3 ml-2" />
          </PopoverButton>

          {open && (
            <PopoverPanel
              static
              className="absolute z-10 mt-1 w-64 max-h-80 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none right-0"
            >
              <div className="p-2">
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Search people..."
                  className="w-full px-3 py-2 text-sm leading-5 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="py-1">
                <button
                  className="flex items-center w-full bg-transparent px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    handleAssigneeChange(null);
                    close();
                  }}
                >
                  <FaUser className="h-4 w-4 mr-3" />
                  No assignee
                </button>
                {filteredUsers.map((user) => (
                  <button
                    key={user.user_id}
                    className="flex items-center w-full bg-transparent px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => {
                      handleAssigneeChange(user);
                      close();
                    }}
                  >
                    {user.avatar ? (
                      <img
                        src={user.avatar}
                        alt={user.name}
                        className="h-6 w-6 rounded-full mr-3"
                      />
                    ) : (
                      <div className="h-6 w-6 rounded-full bg-gray-300 flex items-center justify-center text-xs font-bold mr-3">
                        {getInitials(user.name)}
                      </div>
                    )}
                    {user.name}
                  </button>
                ))}
              </div>
            </PopoverPanel>
          )}
        </>
      )}
    </Popover>
  );
};

export default AssigneeSelector;

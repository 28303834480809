import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Toolbar from "../../components/toolbar/toolbar";
import Menubar from "../../components/menubar/menubar";
import Home from "../../components/home/home";
import Data from "../../components/data/data";
import Model from "../../components/model/model";
import Logout from "../../components/logout/logout";
import FileDetail from "../../components/file/fileDetail";
import Result from "../../components/result/result";
import Footer from "../../components/footer/footer";
import UserService from "../../service/UserService";
import { useUser } from "../../context/userContext";
import Users from "../../components/users/users";
import Report from "../../components/report/report";
import Chat from "../../components/chat/chat";
import "./portal.css";
import Run from "../../components/run/run";
import JobResult from "../../components/run/jobResult/job-result";
import Reports from "../../components/reports/reports";
import WorkspaceUsers from "../../components/users/workspace-users";
import Navbar from "../../components/navbar";
import WorkspaceList from "../../components/workspaces/list";

const Portal = () => {
  const { user } = useUser();

  const renderComponentWithRoleCheck = (component, requiredRoles) => {
    const userRoles =
      user?.data?.roles?.map((role) => role.name.toUpperCase()) || [];
    const normalizedRequiredRoles = requiredRoles.map((role) =>
      role.toUpperCase()
    );

    if (
      userRoles.length > 0 &&
      normalizedRequiredRoles.some((role) => userRoles.includes(role))
    ) {
      return component;
    }
    return <Navigate to="/forbidden" replace />;
  };

  return (
    <div className="flex flex-col bg-accent h-screen">
      {/* <Toolbar /> */}
      <div className="flex h-full flex-1">
        <Menubar />
        <div className="flex-1 m-3 bg-white border shadow-md ml-0 rounded-xl z-1 overflow-y-auto">
          <Navbar />
          <Routes>
            <Route path="/" element={<Navigate to="/portal/home" replace />} />
            <Route
              path="/home"
              element={renderComponentWithRoleCheck(<Home />, [
                "BUSINESS_USER",
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/data"
              element={renderComponentWithRoleCheck(<Data />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/model"
              element={renderComponentWithRoleCheck(<Model />, [
                "SUPER_ADMIN",
                "USER",
              ])}
            />
            <Route
              path="/result"
              element={renderComponentWithRoleCheck(<Result />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/run"
              element={renderComponentWithRoleCheck(<Run />, [
                "SUPER_ADMIN",
                "USER",
              ])}
            />
            <Route
              path="/run/result"
              element={renderComponentWithRoleCheck(<JobResult />, [
                "SUPER_ADMIN",
                "USER",
              ])}
            />
            <Route
              path="/members"
              element={renderComponentWithRoleCheck(<WorkspaceUsers />, [
                "ADMIN",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/users"
              element={renderComponentWithRoleCheck(<Users />, [
                "ADMIN",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/file/:fileName"
              element={renderComponentWithRoleCheck(<FileDetail />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/report"
              element={renderComponentWithRoleCheck(<Report />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/chat"
              element={renderComponentWithRoleCheck(<Chat />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/chat/:chatId"
              element={renderComponentWithRoleCheck(<Chat />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/reports"
              element={renderComponentWithRoleCheck(<Reports />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/workspaces"
              element={renderComponentWithRoleCheck(<WorkspaceList />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/knowledge-base"
              element={renderComponentWithRoleCheck(<Reports />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/documents-used"
              element={renderComponentWithRoleCheck(<Reports />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/reports/:reportId"
              element={renderComponentWithRoleCheck(<Reports />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route
              path="/reports/:reportId/settings"
              element={renderComponentWithRoleCheck(<Reports />, [
                "USER",
                "SUPER_ADMIN",
              ])}
            />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Portal;

import { create } from "zustand";
import NotificationService from "../service/NotificationService";
import useWorkspaceStore from "./workspaceStore";

const useNotificationStore = create((set, get) => ({
  // State
  notifications: [],
  isLoading: false,
  error: null,

  // Actions
  fetchNotifications: async () => {
    set({ isLoading: true, error: null });
    try {
      const response = await NotificationService.getNotifications();
      set({ notifications: response.data });
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to fetch notifications:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  createNotification: async (notificationData) => {
    set({ isLoading: true, error: null });
    try {
      const response = await NotificationService.createNotification(
        notificationData
      );
      set((state) => ({
        notifications: [...state.notifications, response.data],
      }));
      return response.data;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to create notification:", error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  markNotificationRead: async (notificationId) => {
    try {
      await NotificationService.markNotificationRead(notificationId);
      set((state) => ({
        notifications: state.notifications.map((notification) =>
          notification.notification_id === notificationId
            ? { ...notification, status: "read", read_at: new Date() }
            : notification
        ),
      }));
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to mark notification as read:", error);
      throw error;
    }
  },

  markNotificationArchived: async (notificationId) => {
    try {
      await NotificationService.markNotificationArchived(notificationId);
      set((state) => ({
        notifications: state.notifications.map((notification) =>
          notification.notification_id === notificationId
            ? { ...notification, status: "archived", archived_at: new Date() }
            : notification
        ),
      }));
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to archive notification:", error);
      throw error;
    }
  },

  markAllNotificationsRead: async () => {
    try {
      await NotificationService.markAllNotificationsRead();
      set((state) => ({
        notifications: state.notifications.map((notification) => ({
          ...notification,
          status: "read",
          read_at: new Date(),
        })),
      }));
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to mark all notifications as read:", error);
      throw error;
    }
  },

  // Reset store
  reset: () => {
    set({
      notifications: [],
      isLoading: false,
      error: null,
    });
  },
}));

export default useNotificationStore;

import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import UserService from "../../service/UserService";
import RoleService from "../../service/RoleService";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import "primeicons/primeicons.css";
import "./user.css";
import { useUser } from "../../context/userContext";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false); // State to manage dialog visibility
  const [selectedUser, setSelectedUser] = useState(null);
  const toast = useRef(null);
  const { user } = useUser();

  const approveOption = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      role: [],
      isApproved: true,
    },
    validate: (values) => {
      const errors = {};
      if (!values?.name) errors.name = "Name is required";
      if (!values?.email) errors.email = "Email is required";
      if (!values?.password && !selectedUser)
        errors.password = "Password is required";
      if (!values?.role?.length) errors.role = "At least one role is required";
      return errors;
    },
    onSubmit: (values) => {
      if (selectedUser) {
        handleUpdateUser(values);
      } else {
        handleCreateUser(values);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    fetchUsers();
    fetchPendingUsers();
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    try {
      const result = await UserService.getAllUser();
      setUsers(result.data);
    } catch (error) {
      console.error("Failed to fetch users", error);
    }
  };

  const fetchPendingUsers = async () => {
    try {
      const result = await UserService.getUserByIsApproved();
      setPendingUsers(result.data);
    } catch (error) {
      console.error("Failed to fetch pending users", error);
    }
  };

  const fetchRoles = async () => {
    try {
      const result = await RoleService.getAllRole();
      setRoles(result.data);
    } catch (error) {
      console.error("Failed to fetch pending users", error);
    }
  };

  const handleCreateUser = async (values) => {
    const createPayload = {
      name: values.name,
      email: values.email,
      password: values.password,
      roles: values.role,
      is_approved: values.isApproved,
      org_id: user.data.org_id,
    };
    try {
      await UserService.createUser(createPayload);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User added successfully",
      });
      fetchUsers();
      formik.resetForm();
      setDialogVisible(false);
      setSelectedUser(null);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to save user",
      });
    }
  };

  const handleUpdateUser = async (values) => {
    console.log("Updating user", values);
    const updatePayload = {
      name: values.name,
      email: values.email,
      roles: values.role,
      is_approved: values.isApproved,
    };
    try {
      await UserService.updateUser(selectedUser.id, updatePayload);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User updated successfully",
      });
      fetchUsers();
      formik.resetForm();
      setDialogVisible(false);
      setSelectedUser(null);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to update user",
      });
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    formik.setValues({
      name: user.name,
      email: user.email,
      role: user.roles,
      isApproved: user.is_approved,
    });
    setDialogVisible(true);
  };

  const handleDeleteUser = async (id) => {
    try {
      await UserService.deleteUser(id);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User deleted successfully",
      });
      fetchUsers();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete user",
      });
    }
  };

  const handleApproveUser = async (userId) => {
    try {
      await UserService.approveUser(userId);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User approved successfully",
      });
      fetchPendingUsers();
      fetchUsers();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to approve user",
      });
    }
  };

  const openNewUserDialog = () => {
    setSelectedUser(null);
    formik.resetForm();
    setDialogVisible(true);
  };

  const dialogFooter = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setDialogVisible(false)}
        className="p-button-text"
      />
      {!selectedUser ? (
        <Button
          label="Create"
          icon="pi pi-check"
          type="submit"
          onClick={formik.handleSubmit}
        />
      ) : (
        <Button
          label="Update"
          icon="pi pi-check"
          type="submit"
          onClick={formik.handleSubmit}
        />
      )}
    </div>
  );

  const roleBodyTemplate = (rowData) => {
    const roleNames = rowData.roles
      ? rowData.roles.map((role) => role.name).join(", ")
      : "No Role";
    return <span>{roleNames}</span>;
  };

  const actionsBodyTemplate = (rowData) => (
    <div>
      <Button
        icon="pi pi-user-edit"
        className="p-button-rounded p-button-text p-mr-2"
        onClick={() => handleEditUser(rowData)}
        tooltip="Edit"
        tooltipOptions={{ position: "top" }}
      />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-text "
        onClick={() => handleDeleteUser(rowData.id)}
        tooltip="Delete"
        tooltipOptions={{ position: "top" }}
      />
    </div>
  );

  const pendingActionsBodyTemplate = (rowData) => (
    <div>
      <Button
        icon="pi pi-verified"
        className="p-button-rounded p-button-text p-mr-2"
        onClick={() => handleApproveUser(rowData.id)}
        tooltip="Approve"
        tooltipOptions={{ position: "top" }}
      />
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text"
        // onClick={() => handleDisapproveUser(rowData.id)}
        tooltip="Not Approve"
        tooltipOptions={{ position: "top" }}
      />
    </div>
  );

  return (
    <div className="user-container">
      <Toast ref={toast} position="bottom-right" />
      <div className="user-management-upper">
        <div className="user-header">
          <span className="user-title">User Management</span>
          <Button
            label="Create New User"
            icon="pi pi-plus"
            onClick={openNewUserDialog}
            className="p-mb-3"
          />
        </div>

        <Dialog
          header={selectedUser ? "Update User" : "Add New User"}
          visible={dialogVisible}
          className="max-w-2xl"
          footer={dialogFooter}
          onHide={() => setDialogVisible(false)}
        >
          <form onSubmit={formik.handleSubmit} className="space-y-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="name" className="font-medium">
                Name <span className="text-red-500">*</span>
              </label>
              <InputText
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={classNames("w-full p-2 border rounded-md", {
                  "border-red-500": isFormFieldValid("name"),
                })}
              />
              {getFormErrorMessage("name")}
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="email" className="font-medium">
                Email <span className="text-red-500">*</span>
              </label>
              <InputText
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={classNames("w-full p-2 border rounded-md", {
                  "border-red-500": isFormFieldValid("email"),
                })}
              />
              {getFormErrorMessage("email")}
            </div>

            {!selectedUser && (
              <div className="flex flex-col gap-2">
                <label htmlFor="password" className="font-medium">
                  Password <span className="text-red-500">*</span>
                </label>
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  toggleMask
                  feedback={false}
                  className={classNames("w-full", {
                    "border-red-500": isFormFieldValid("password"),
                  })}
                />
                {getFormErrorMessage("password")}
              </div>
            )}

            <div className="flex flex-col gap-2">
              <label htmlFor="role" className="font-medium">
                Role <span className="text-red-500">*</span>
              </label>
              <MultiSelect
                id="role"
                name="role"
                value={formik.values.role}
                options={roles}
                optionLabel="name"
                onChange={(e) => formik.setFieldValue("role", e.value)}
                className={classNames("w-full", {
                  "border-red-500": isFormFieldValid("role"),
                })}
                display="chip"
              />
              {getFormErrorMessage("role")}
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="isApproved" className="font-medium">
                Approved
              </label>
              <Dropdown
                id="isApproved"
                name="isApproved"
                value={formik.values.isApproved}
                options={approveOption}
                onChange={formik.handleChange}
                className={classNames("w-full", {
                  "border-red-500": isFormFieldValid("isApproved"),
                })}
              />
              {getFormErrorMessage("isApproved")}
            </div>
          </form>
        </Dialog>

        <h3>All Users</h3>
        <DataTable value={users} paginator rows={5} className="user-table">
          <Column field="name" header="Name" />
          <Column field="email" header="Email" />
          <Column body={roleBodyTemplate} header="Role" />
          <Column
            field="is_approved"
            header="Approved"
            body={(rowData) => (rowData.is_approved ? "Yes" : "No")}
          />
          <Column header="Actions" body={actionsBodyTemplate} />
        </DataTable>
      </div>

      <div className="user-management-lower">
        <h3>Pending Approvals</h3>
        <DataTable
          value={pendingUsers}
          paginator
          rows={5}
          className="user-table"
        >
          <Column field="name" header="Name" />
          <Column field="email" header="Email" />
          <Column header="Actions" body={pendingActionsBodyTemplate} />
        </DataTable>
      </div>
    </div>
  );
};

export default Users;

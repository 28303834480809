import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ActivityService from '../../service/ActivityService';
import useWorkspaceStore from '../../stores/workspaceStore';
import useReportStore from '../../stores/reportStore';
import useProjectStore from '../../stores/projectStore';
import useNotificationStore from '../../stores/notificationStore';
import useFolderStore from '../../stores/folderStore';
import useChatStore from '../../stores/chatStore';

const Logout = () => {
    const navigate = useNavigate();
    const resetWorkspace = useWorkspaceStore((state) => state.reset);
    const resetReport = useReportStore((state) => state.reset);
    const resetProject = useProjectStore((state) => state.reset);
    const resetNotification = useNotificationStore((state) => state.reset);
    const resetFolder = useFolderStore((state) => state.reset);
    const resetChat = useChatStore((state) => state.reset);


    const logActivity = async (action, userId, email) => {
        try {
            const activityLog = {
                email_id: email,
                action: action
            };
            await ActivityService.addActivityLog(activityLog, userId);
        } catch (error) {
            console.error('Failed to log activity:', error);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            logActivity('User logged out', user?.data?.id, user?.data?.email);
        }

        // Clear all stores
        resetWorkspace();
        resetReport();
        resetProject();
        resetNotification()
        resetFolder()
        resetChat()
        
        localStorage.removeItem('token');
        sessionStorage.clear();
        localStorage.removeItem('user');

        navigate('/');
    }, [navigate]);

    return (
        <div>
        </div>
    );
};

export default Logout;

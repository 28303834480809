import React, { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TabLayout from "../layout/reportsTabLayout";
import ReportList from "./reports-list";
import ReportDetails from "./report-details";
import ReportSettings from "./report-settings";
import KnowledgeGraphComponent from "./knowledge-graph-component";
import ReportService from "../../service/ReportService";
import DocumentsUsed from "./documents-used";
import useProjectStore from "../../stores/projectStore";
import useReportStore from "../../stores/reportStore";
import useFolderStore from "../../stores/folderStore";

const Reports = () => {
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [knowledgeGraphData, setKnowledgeGraphData] = useState(null);
  const [isLoadingKnowledgeGraph, setIsLoadingKnowledgeGraph] = useState(false);
  const navigate = useNavigate();
  const { reportId } = useParams();
  const location = useLocation();
  const isSettingsRoute = location.pathname.endsWith("/settings");
  const { selectedProject } = useProjectStore();
  const { reports, isLoading, fetchReports, updateReports, createReport } =
    useReportStore();

  const { folders, fetchFolders, updateFolders } = useFolderStore();

  useEffect(() => {
    if (!selectedProject?.project_id) {
      return;
    }

    fetchReports(selectedProject?.project_id);
    fetchFolders(selectedProject?.project_id);

    const fetchKnowledgeGraph = async () => {
      try {
        setIsLoadingKnowledgeGraph(true);
        const data = await ReportService.knowledgeGraphForReport({
          project_id: selectedProject.project_id,
        });
        setKnowledgeGraphData(data.knowledgeGraph);
      } catch (error) {
        console.error("Error fetching knowledge graph:", error);
        showToast("error", "Error", "Failed to fetch knowledge graph");
      } finally {
        setIsLoadingKnowledgeGraph(false);
      }
    };
    fetchKnowledgeGraph();
  }, [selectedProject?.project_id]);

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const renderContent = () => {
    if (activeIndex === 1) {
      return (
        <div className="col-span-4 h-full">
          <KnowledgeGraphComponent
            data={knowledgeGraphData}
            isLoading={isLoadingKnowledgeGraph}
          />
        </div>
      );
    }

    if (activeIndex === 2) {
      return (
        <div className="grid col-span-4 w-full h-full">
          <DocumentsUsed reportId={reportId} />
        </div>
      );
    }

    return (
      <div className="grid grid-cols-4 gap-4 h-full">
        <ReportList activeReportId={reportId} onShowToast={showToast} />
        {isSettingsRoute ? (
          <ReportSettings
            reports={reports}
            onUpdateReports={updateReports}
            onShowToast={showToast}
          />
        ) : (
          <ReportDetails
            reports={reports}
            reportId={reportId}
            onUpdateReports={updateReports}
            onShowToast={showToast}
          />
        )}
      </div>
    );
  };

  return (
    <div className="h-full overflow-hidden">
      <TabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
        {renderContent()}
        <Toast ref={toast} position="bottom-right" />
      </TabLayout>
    </div>
  );
};

export default Reports;

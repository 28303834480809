import { create } from "zustand";
import ReportService from "../service/ReportService";

const useFolderStore = create((set, get) => ({
  // State
  folders: [],
  selectedFolder: null,
  isLoading: false,
  error: null,

  // Actions
  fetchFolders: async (projectId) => {
    set({ isLoading: true, error: null });
    try {
      const fetchedFolders = await ReportService.getProjectFolders(projectId);
      set({ folders: fetchedFolders });
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to fetch folders:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  setSelectedFolder: (folder) => {
    set({ selectedFolder: folder });
  },

  createFolder: async (folderData) => {
    set({ isLoading: true, error: null });
    try {
      const createdFolder = await ReportService.createFolder(folderData);
      set((state) => ({
        folders: [...state.folders, createdFolder],
        selectedFolder: createdFolder,
      }));
      return createdFolder;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to create folder:", error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  updateFolders: (updatedFolders) => {
    set({ folders: updatedFolders });
  },

  deleteFolder: async (folderId) => {
    set({ isLoading: true, error: null });
    try {
      await ReportService.deleteFolder(folderId);
      set((state) => ({
        folders: state.folders.filter((folder) => folder.id !== folderId),
        selectedFolder:
          state.selectedFolder?.id === folderId ? null : state.selectedFolder,
      }));
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to delete folder:", error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  // Reset store
  reset: () => {
    set({
      folders: [],
      selectedFolder: null,
      isLoading: false,
      error: null,
    });
  },
}));

export default useFolderStore;

export const MENU_ITEMS = [
  {
    path: "/portal/home",
    icon: "pi pi-home",
    label: "Dashboard",
    accessIdentifier: "viewDashboard",
    roles: ["BUSINESS_USER", "USER", "SUPER_ADMIN"],
  },
  {
    path: "/portal/data",
    icon: "pi pi-database",
    label: "Data",
    accessIdentifier: "viewWorkspaceData",
    roles: ["USER", "SUPER_ADMIN"],
  },
  {
    path: "/portal/model",
    icon: "pi pi-sitemap",
    label: "Train Model",
    accessIdentifier: "viewTrainModel",
    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/run",
    icon: "pi pi-play",
    label: "Run Model",
    accessIdentifier: "viewRunModel",

    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/members",
    icon: "pi pi-users",
    label: "Members",
    accessIdentifier: "viewMembers",
    roles: ["SUPER_ADMIN", "ADMIN"],
  },
  {
    path: "/portal/chat",
    icon: "pi pi-comments",
    accessIdentifier: "viewDataTalk",
    label: "Data Talk",
    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/reports",
    icon: "pi pi-file",
    accessIdentifier: "viewReports",
    label: "Reports",
    roles: ["SUPER_ADMIN", "USER"],
  },
  {
    path: "/portal/workspaces",
    icon: "pi pi-folder",
    accessIdentifier: "viewWorkspaceData",
    label: "Workspaces",
    roles: ["SUPER_ADMIN", "USER"],
  },
];

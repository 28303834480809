import React, { useEffect, useState } from "react";
import {
  HiPlus,
  HiOutlineUserCircle,
  HiOutlineMail,
  HiCheck,
} from "react-icons/hi";
import useWorkspaceStore from "../../stores/workspaceStore";
import UserService from "../../service/UserService";
import WorkspaceUser from "./components/workspace-user";

const PERMISSION_GROUPS = [
  "WORKSPACE_OWNER",
  "WORKSPACE_ADMIN",
  "WORKSPACE_EDITOR",
  "WORKSPACE_VIEWER",
  "WORKSPACE_REPORTS_EDITOR",
].map((group) => ({
  label: group.replace(/_/g, " "),
  value: group,
}));

const WorkspaceUsers = () => {
  const {
    workspaceUsers,
    selectedWorkspace,
    fetchWorkspaceUsers,
    inviteWorkspaceUser,
    isLoading,
  } = useWorkspaceStore();

  const [showInviteForm, setShowInviteForm] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    name: "",
    permissionGroups: [],
  });
  const [allUsers, setAllUsers] = useState([]);
  const [selectedExistingUser, setSelectedExistingUser] = useState(null);
  const [showPermissionDropdown, setShowPermissionDropdown] = useState(false);

  useEffect(() => {
    if (selectedWorkspace) {
      fetchWorkspaceUsers(selectedWorkspace.workspace_id);
      fetchAllUsers();
    }
  }, [selectedWorkspace, fetchWorkspaceUsers]);

  const fetchAllUsers = async () => {
    try {
      const result = await UserService.getAllUser();
      setAllUsers(result.data);
    } catch (error) {
      console.error("Failed to fetch users", error);
    }
  };

  const handleInviteUser = async (e) => {
    e.preventDefault();
    try {
      if (selectedExistingUser) {
        await inviteWorkspaceUser(selectedWorkspace.workspace_id, {
          permission_groups: newUser.permissionGroups,
          invitee_id: selectedExistingUser.id,
        });
      } else {
        await inviteWorkspaceUser(selectedWorkspace.workspace_id, newUser);
      }
      setNewUser({ email: "", name: "", permissionGroups: [] });
      setSelectedExistingUser(null);
      setShowInviteForm(false);
    } catch (error) {
      console.error("Failed to invite user:", error);
    }
  };

  const togglePermissionGroup = (permission) => {
    setNewUser((prev) => {
      const newGroups = prev.permissionGroups.includes(permission.value)
        ? prev.permissionGroups.filter((g) => g !== permission.value)
        : [...prev.permissionGroups, permission.value];
      return { ...prev, permissionGroups: newGroups };
    });
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Workspace Members
        </h1>
        <button
          onClick={() => setShowInviteForm(true)}
          className="inline-flex items-center px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
        >
          <HiPlus className="mr-2" />
          Invite People
        </button>
      </div>

      {showInviteForm && (
        <div className="mb-8 bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <form onSubmit={handleInviteUser}>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Select Existing User
                </label>
                <select
                  value={selectedExistingUser ? selectedExistingUser.id : ""}
                  onChange={(e) => {
                    const user = allUsers.find(
                      (u) => u.id === parseInt(e.target.value)
                    );
                    setSelectedExistingUser(user);
                    if (user) {
                      setNewUser({ email: "", name: "", permissionGroups: [] });
                    }
                  }}
                  className="w-full rounded-md border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
                >
                  <option value="">-- Select User --</option>
                  {allUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.email})
                    </option>
                  ))}
                </select>
              </div>

              <div className="text-center my-4">
                <span className="text-gray-500">OR</span>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <div className="relative">
                  <HiOutlineUserCircle className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    value={newUser.name}
                    onChange={(e) => {
                      setNewUser({ ...newUser, name: e.target.value });
                      setSelectedExistingUser(null);
                    }}
                    className="pl-10 w-full rounded-md border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
                    placeholder="Enter name"
                    disabled={selectedExistingUser !== null}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <div className="relative">
                  <HiOutlineMail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                  <input
                    type="email"
                    value={newUser.email}
                    onChange={(e) => {
                      setNewUser({ ...newUser, email: e.target.value });
                      setSelectedExistingUser(null);
                    }}
                    className="pl-10 w-full rounded-md border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
                    placeholder="Enter email"
                    disabled={selectedExistingUser !== null}
                  />
                </div>
              </div>
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Permission Groups
                </label>
                <button
                  type="button"
                  onClick={() =>
                    setShowPermissionDropdown(!showPermissionDropdown)
                  }
                  className="w-full px-4 py-2 text-left border rounded-md bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
                >
                  {newUser.permissionGroups.length === 0 ? (
                    <span className="text-gray-500">
                      Select permission groups...
                    </span>
                  ) : (
                    <span>
                      {newUser.permissionGroups.length} groups selected
                    </span>
                  )}
                </button>
                {showPermissionDropdown && (
                  <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
                    {PERMISSION_GROUPS.map((permission) => (
                      <div
                        key={permission.value}
                        onClick={() => togglePermissionGroup(permission)}
                        className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <div className="w-5 h-5 border rounded mr-3 flex items-center justify-center">
                          {newUser.permissionGroups.includes(
                            permission.value
                          ) && <HiCheck className="text-black" />}
                        </div>
                        {permission.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowInviteForm(false);
                    setSelectedExistingUser(null);
                    setNewUser({ email: "", name: "", permissionGroups: [] });
                  }}
                  className="px-4 py-2 text-sm text-gray-700 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
                  disabled={
                    (!selectedExistingUser &&
                      (!newUser.email || !newUser.name)) ||
                    !newUser.permissionGroups.length
                  }
                >
                  Send Invite
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        {isLoading ? (
          <div className="p-8 text-center text-gray-500">Loading...</div>
        ) : workspaceUsers.length === 0 ? (
          <div className="p-8 text-center text-gray-500">No members yet</div>
        ) : (
          <ul className="divide-y divide-gray-200">
            {workspaceUsers.map((user) => (
              <WorkspaceUser user={user} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default WorkspaceUsers;

import { create } from "zustand";

const useChatStore = create((set) => ({
  chatHistory: [],
  selectedChatId: null,

  setChatHistory: (history) => set({ chatHistory: history }),
  setSelectedChatId: (id) => set({ selectedChatId: id }),

  addChat: (chat) =>
    set((state) => ({
      chatHistory: [...state.chatHistory, chat],
    })),

  updateChat: (chatId, messages) =>
    set((state) => ({
      chatHistory: state.chatHistory.map((chat) =>
        chat.id === chatId ? { ...chat, messages } : chat
      ),
    })),

  reset: () => set({ chatHistory: [], selectedChatId: null }),
}));

export default useChatStore;

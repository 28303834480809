import React from "react";
import { AiOutlineClose, AiOutlineUpload } from "react-icons/ai";

const ChooseTemplateModal = ({
  isOpen,
  onClose,
  selectedTemplateId,
  onTemplateSelect,
}) => {
  if (!isOpen) return null;

  const templates = [
    {
      id: "template1",
      name: "Basic Report",
      description: "Clean and simple template for standard reports",
      icon: "📄",
    },
    {
      id: "template2",
      name: "Legal Document",
      description:
        "Professional template for legal motions and filings with attorney information",
      icon: "⚖️",
    },
    {
      id: "template3",
      name: "Compliance Report",
      description:
        "Structured template for regulatory compliance documentation and analysis",
      icon: "📋",
    },
  ];

  const handleUploadClick = () => {
    alert(
      "This feature is not available on the free plan. Please upgrade to use this feature."
    );
  };

  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white/90 backdrop-blur-xl p-8 rounded-2xl w-[800px] max-h-[80vh] overflow-y-auto shadow-2xl">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-semibold text-gray-900">
            Choose a Template
          </h2>
          <button
            onClick={onClose}
            className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors duration-200"
            aria-label="Close modal"
          >
            <AiOutlineClose className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        <div className="grid grid-cols-3 gap-6">
          {templates.map((template) => (
            <button
              key={template.id}
              onClick={() => onTemplateSelect(template.id)}
              className={`group p-6 rounded-xl transition-all duration-300 text-left
                ${
                  template.id === selectedTemplateId
                    ? "bg-blue-50/80 ring-2 ring-blue-500 shadow-lg scale-[1.02]"
                    : "hover:bg-gray-50/80 hover:ring-2 bg-transparent hover:ring-gray-200 hover:scale-[1.02]"
                }`}
            >
              <div className="text-4xl mb-4 group-hover:scale-110 transition-transform duration-300">
                {template.icon}
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {template.name}
              </h3>
              <p className="text-sm text-gray-500 leading-relaxed">
                {template.description}
              </p>
            </button>
          ))}
        </div>

        <div className="mt-8">
          <button
            onClick={handleUploadClick}
            className="w-full py-3 px-4 bg-gradient-to-r from-gray-50 to-gray-100 hover:from-gray-100 hover:to-gray-200 text-gray-700 rounded-xl transition-all duration-300 flex items-center justify-center space-x-3 group relative shadow-sm hover:shadow-md border border-gray-200/50"
            title="Upgrade required for this feature"
          >
            <AiOutlineUpload className="w-5 h-5 group-hover:scale-110 transition-transform duration-200" />
            <span>Upload Custom Template</span>
            <span className="absolute -top-2 -right-2 bg-yellow-400 text-xs text-gray-800 px-2 py-0.5 rounded-full font-medium">
              PRO
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseTemplateModal;

import React, { useState, useEffect, useRef } from "react";
import { FaUser } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import useWorkspaceStore from "../../stores/workspaceStore";

const Comment = ({ comment }) => {
  const highlightMentions = (text) => {
    const parts = text.split(/(@\[\d+\]\w+)/g);
    return parts.map((part, i) => {
      const mentionMatch = part.match(/@\[(\d+)\](\w+)/);
      if (mentionMatch) {
        return (
          <span key={i} className="text-blue-500 font-medium">
            @{mentionMatch[2]}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="flex items-start mb-4">
      <div className="mr-3">
        <FaUser className="w-8 h-8 text-gray-400" />
      </div>
      <div className="flex-grow">
        <div className="flex items-center mb-1">
          <span className="font-semibold text-sm mr-2">
            User {comment.authorId}
          </span>
          <span className="text-xs text-gray-500">
            {comment.createdAt.toLocaleString()}
          </span>
        </div>
        <div className="prose prose-sm max-w-none text-gray-700">
          <ReactMarkdown
            components={{
              p: ({ children }) => (
                <p>{highlightMentions(children.toString())}</p>
              ),
            }}
          >
            {comment.content.text}
          </ReactMarkdown>
        </div>
        {comment.isResolved && (
          <span className="text-xs text-green-600 mt-1">Resolved</span>
        )}
        {comment.visibility !== "public" && (
          <span className="text-xs text-gray-500 mt-1">
            ({comment.visibility})
          </span>
        )}
      </div>
    </div>
  );
};

const CommentSection = ({
  comments,
  onAddComment,
  onCommentChange,
  newComment,
}) => {
  const [showMentionPanel, setShowMentionPanel] = useState(false);
  const [mentionSearch, setMentionSearch] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const textareaRef = useRef(null);
  const { selectedWorkspace, workspaceUsers, fetchWorkspaceUsers } =
    useWorkspaceStore();

  useEffect(() => {
    if (selectedWorkspace?.workspace_id) {
      fetchWorkspaceUsers(selectedWorkspace.workspace_id);
    }
  }, [selectedWorkspace, fetchWorkspaceUsers]);

  useEffect(() => {
    // Convert stored format to display format when newComment changes
    const displayVersion = newComment.replace(/@\[(\d+)\](\w+)/g, "@$2");
    setDisplayText(displayVersion);
  }, [newComment]);

  const handleTextareaChange = (e) => {
    const value = e.target.value;
    const cursorPos = e.target.selectionStart;
    setCursorPosition(cursorPos);
    setDisplayText(value);

    // Convert any existing mentions to storage format before saving
    const storageValue = value.replace(/@(\w+)/g, (match, username) => {
      const user = workspaceUsers.find((u) => u.name === username);
      return user ? `@[${user.user_id}]${username}` : match;
    });

    onCommentChange({ target: { value: storageValue } });

    // Check if @ was just typed
    if (value[cursorPos - 1] === "@") {
      setShowMentionPanel(true);
      setMentionSearch("");
    } else if (showMentionPanel) {
      // Update mention search if panel is open
      const lastAtPos = value.lastIndexOf("@", cursorPos - 1);
      if (lastAtPos !== -1) {
        setMentionSearch(value.slice(lastAtPos + 1, cursorPos).toLowerCase());
      } else {
        setShowMentionPanel(false);
      }
    }
  };

  const handleMentionSelect = (user) => {
    const textBeforeCursor = displayText.slice(0, cursorPosition);
    const lastAtPos = textBeforeCursor.lastIndexOf("@");
    const textAfterCursor = displayText.slice(cursorPosition);

    // Update display text with just the username
    const newDisplayValue =
      textBeforeCursor.slice(0, lastAtPos) + `@${user.name} ` + textAfterCursor;
    setDisplayText(newDisplayValue);

    // Update storage text with the full mention format
    const newStorageValue =
      textBeforeCursor.slice(0, lastAtPos) +
      `@[${user.user_id}]${user.name} ` +
      textAfterCursor;

    onCommentChange({ target: { value: newStorageValue } });
    setShowMentionPanel(false);

    // Update cursor position and focus
    if (textareaRef.current) {
      const newCursorPos = lastAtPos + user.name.length + 2; // @ + username + space
      textareaRef.current.focus();
      setTimeout(() => {
        textareaRef.current.setSelectionRange(newCursorPos, newCursorPos);
      }, 0);
    }
  };

  const filteredUsers = workspaceUsers.filter((user) =>
    user.name.toLowerCase().includes(mentionSearch)
  );

  return (
    <div className="mt-4">
      <div className="flex mb-4 items-start">
        <div className="mr-3">
          <FaUser className="w-8 h-8 text-gray-400" />
        </div>
        <div className="flex-grow relative">
          <textarea
            ref={textareaRef}
            value={displayText}
            onChange={handleTextareaChange}
            className="w-full p-2 mb-2 border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md placeholder-gray-400 text-gray-700"
            rows="2"
            placeholder="Leave a comment... Use @ to mention users"
            onKeyDown={(e) => {
              if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                onAddComment();
              } else if (e.key === "Escape") {
                setShowMentionPanel(false);
              }
            }}
            onBlur={() => {
              // Delay hiding panel to allow for clicks on the panel
              setTimeout(() => setShowMentionPanel(false), 200);
            }}
          />
          {showMentionPanel && (
            <div className="absolute z-10 w-64 max-h-48 overflow-y-auto bg-white border border-gray-200 rounded-md shadow-lg">
              {filteredUsers.map((user) => (
                <div
                  key={user.user_id}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={() => handleMentionSelect(user)}
                >
                  <FaUser className="w-4 h-4 mr-2 text-gray-400" />
                  <span>{user.name}</span>
                </div>
              ))}
              {filteredUsers.length === 0 && (
                <div className="px-4 py-2 text-gray-500">No users found</div>
              )}
            </div>
          )}
          <button
            onClick={onAddComment}
            className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded text-sm"
          >
            Comment
          </button>
        </div>
      </div>
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </div>
  );
};

export default CommentSection;

import axios from "axios";
import { WORKSPACE_API_BASE_URL } from "../utils/config";

const NotificationService = {
  createNotification: async (notificationData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${WORKSPACE_API_BASE_URL}/notification`,
        notificationData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getNotifications: async (workspaceId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${WORKSPACE_API_BASE_URL}/notifications`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  markNotificationRead: async (notificationId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${WORKSPACE_API_BASE_URL}/notification/${notificationId}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  markNotificationArchived: async (notificationId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${WORKSPACE_API_BASE_URL}/notification/${notificationId}/archive`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  markAllNotificationsRead: async (workspaceId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${WORKSPACE_API_BASE_URL}/notifications/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default NotificationService;

import React, { useEffect, useState, useCallback, memo } from "react";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";
import QuestionItem from "./question-item";
import { useDropzone } from "react-dropzone";
import { AiOutlineFilePdf, AiOutlineUpload } from "react-icons/ai";
import S3Service from "../../service/S3service";
import ReportService from "../../service/ReportService";
import TemplateOne from "./templates/TemplateOne";
import TemplateTwo from "./templates/TemplateTwo";
import TemplateThree from "./templates/TemplateThree";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useReportStore from "../../stores/reportStore";
import ChooseTemplateModal from "./choose-template-modal";

const templateComponents = {
  template1: TemplateOne,
  template2: TemplateTwo,
  template3: TemplateThree,
  default: TemplateOne,
};
const ReportDetails = ({ reports, reportId, onShowToast }) => {
  const [report, setReport] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("template1");
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [showLogoUpload, setShowLogoUpload] = useState(false);

  useEffect(() => {
    const fetchReport = async () => {
      setIsLoading(true);
      try {
        const reportData = await ReportService.getReportById(reportId);
        setReport(reportData);
      } catch (error) {
        console.error("Error fetching report:", error);
        onShowToast("error", "Error", "Failed to fetch report");
      } finally {
        setIsLoading(false);
      }
    };
    if (reportId) {
      fetchReport();
    }
  }, [reportId, onShowToast]);

  const handlePreview = useCallback(() => {
    const previewUrl = `${window.location.origin}/preview/report/${reportId}`;
    window.open(previewUrl, "_blank");
  }, [reportId]);

  const debouncedUpdateReport = useCallback(
    debounce(async (updatedReport) => {
      try {
        await ReportService.updateReport(
          updatedReport.report_id,
          updatedReport
        );
        setReport(updatedReport);
      } catch (error) {
        console.error("Error updating report:", error);
        onShowToast("error", "Error", "Failed to update report");
      }
    }, 500),
    [onShowToast]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateReport.cancel();
    };
  }, [debouncedUpdateReport]);

  const handleLogoUpload = useCallback(
    async (file) => {
      if (!file || !report) return;

      setIsUploadingLogo(true);
      try {
        const fileName = `logos-${report.report_id}-${uuidv4()}-${file.name}`;
        const response = await S3Service.uploadFile(fileName, file);

        if (response.status === "OK") {
          const updatedReport = {
            ...report,
            logo: response.data,
            updatedAt: new Date().toISOString(),
          };
          setReport(updatedReport);
          debouncedUpdateReport(updatedReport);
        }
      } catch (error) {
        console.error("Error uploading logo:", error);
        onShowToast("error", "Upload Failed", "Failed to upload logo");
      } finally {
        setIsUploadingLogo(false);
      }
    },
    [report, debouncedUpdateReport, onShowToast]
  );
  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      // Handle drops into compliance section
      if (
        result.destination.droppableId === "new-compliance-question" ||
        result.destination.droppableId.startsWith("compliance-")
      ) {
        const questionId = result.draggableId;
        const cellId = result.destination.droppableId;

        // Check if question is already mapped somewhere
        const isQuestionMapped = Object.values(
          report.metadata?.cellMappings || {}
        ).includes(questionId);
        if (isQuestionMapped) {
          return; // Don't allow dropping if already mapped
        }

        // If it's a new compliance question
        if (cellId === "new-compliance-question") {
          const newQuestion = { id: uuidv4() };
          const updatedReport = {
            ...report,
            metadata: {
              ...report.metadata,
              complianceQuestions: [
                ...(report.metadata?.complianceQuestions || []),
                newQuestion,
              ],
              cellMappings: {
                ...report.metadata?.cellMappings,
                [`compliance-${newQuestion.id}`]: questionId,
              },
            },
            updatedAt: new Date().toISOString(),
          };

          setReport(updatedReport);
          debouncedUpdateReport(updatedReport);
          return;
        }

        // If it's an existing compliance question
        const updatedReport = {
          ...report,
          metadata: {
            ...report.metadata,
            cellMappings: {
              ...report.metadata?.cellMappings,
              [cellId]: questionId,
            },
          },
          updatedAt: new Date().toISOString(),
        };

        setReport(updatedReport);
        debouncedUpdateReport(updatedReport);
        return;
      }

      // Handle regular question reordering
      const items = Array.from(report.questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedQuestions = items.map((question, index) => ({
        ...question,
        position: index,
      }));

      const updatedReport = {
        ...report,
        questions: updatedQuestions,
        updatedAt: new Date().toISOString(),
      };

      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);
    },
    [report, setReport, debouncedUpdateReport]
  );

  const handleTitleChange = useCallback(
    (newTitle) => {
      if (!report) return;
      const updatedReport = {
        ...report,
        title: newTitle,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);
    },
    [report, debouncedUpdateReport]
  );
  const handleConfigureTemplate = useCallback(() => {
    setIsTemplateModalOpen(true);
  }, []);
  const handleTemplateSelect = useCallback(
    (templateId) => {
      setSelectedTemplateId(templateId);
      console.log(`Selected template: ${templateId}`);
      setIsTemplateModalOpen(false);

      if (!report) return;
      const updatedReport = {
        ...report,
        template_id: templateId,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);

      onShowToast(
        "success",
        "Template Updated",
        "Report template has been updated"
      );
    },
    [onShowToast, report, debouncedUpdateReport]
  );
  const handleMarkAsCompleted = useCallback(() => {
    if (!report) return;

    const previousReport = report;
    const updatedReport = {
      ...report,
      completed: true,
      updatedAt: new Date().toISOString(),
    };
    setReport(updatedReport);
    try {
      debouncedUpdateReport(updatedReport);
      onShowToast(
        "success",
        "Report Completed",
        "Report has been marked as completed"
      );
    } catch (error) {
      setReport(previousReport);
      onShowToast(
        "error",
        "Update Failed",
        "Failed to mark report as completed"
      );
    }
  }, [report, debouncedUpdateReport, onShowToast]);

  const handleExport = useCallback(() => {
    onShowToast("info", "Export", "Export functionality not implemented yet");
  }, [onShowToast]);

  const handleAddQuestion = useCallback(() => {
    if (!report) return;
    const newQuestion = {
      question: "",
      answer: "",
      comment: "",
      status: "draft",
      visualization: null,
      position: report.questions.length,
    };
    const updatedReport = {
      ...report,
      questions: [...report.questions, newQuestion],
      updatedAt: new Date().toISOString(),
    };
    setReport(updatedReport);
  }, [report]);

  const handleDeleteQuestion = useCallback(
    async (questionId) => {
      if (!report) return;

      const updatedQuestions = report.questions.filter(
        (q) => q.question_id !== questionId
      );
      const updatedReport = {
        ...report,
        questions: updatedQuestions,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);

      try {
        if (questionId) {
          await ReportService.deleteQuestion(report.report_id, questionId);
        }
      } catch (error) {
        console.error("Failed to delete question:", error);
        setReport(report);
        onShowToast(
          "error",
          "Delete Failed",
          "Failed to delete question. Please try again."
        );
      }
    },
    [report, onShowToast]
  );
  const { startReportWithQuestionnaire } = useReportStore();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file || !report) return;

      setIsUploading(true);
      try {
        // Start questionnaire process
        const fileDetails = await startReportWithQuestionnaire(
          report.report_id,
          file
        );

        if (fileDetails) {
          onShowToast(
            "success",
            "File Uploaded",
            "Your file has been uploaded successfully. Processing questions..."
          );

          // Refresh report data to get new questions
          const reportData = await ReportService.getReportById(
            report.report_id
          );
          setReport(reportData);
        }
      } catch (error) {
        console.error("Error processing file:", error);
        onShowToast(
          "error",
          "Upload Failed",
          "There was an error processing your file. Please try again."
        );
      } finally {
        setIsUploading(false);
      }
    },
    [report, onShowToast]
  );

  // Mock fetch function
  const fetchGeneratedQuestions = async (fileURL) => {
    // Simulate a delay for fetching questions
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          "What are the key findings from the document?",
          "Can you elaborate on the main challenges discussed?",
          "What recommendations are provided in the document?",
        ]);
      }, 1000);
    });
  };

  // Initialize react-dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
    multiple: false,
    disabled: isUploading,
  });

  const SelectedTemplate =
    templateComponents[report?.template_id] || templateComponents.default;

  if (!reportId) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">
          Select a report or create a new one to get started.
        </p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="col-span-3 w-full p-6 bg-white overflow-y-scroll h-full mx-auto">
        <div className="mb-8 animate-pulse">
          <div className="h-12 w-3/4 bg-gray-200 rounded mb-2"></div>
          <div className="flex items-center text-sm text-gray-500">
            <div className="h-4 w-32 bg-gray-200 rounded mr-2"></div>
            <span>•</span>
            <div className="h-4 w-40 bg-gray-200 rounded ml-2"></div>
          </div>
        </div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="mb-6 animate-pulse">
            <div className="h-24 bg-gray-200 rounded mb-4"></div>
            <div className="h-16 bg-gray-100 rounded"></div>
          </div>
        ))}
        <div className="fixed bottom-6 right-6 flex space-x-2">
          <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-10 w-24 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">Report not found or failed to load.</p>
      </div>
    );
  }

  return (
    <>
      <div className="col-span-3 w-full p-6 bg-white overflow-y-scroll h-full mx-auto">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="mb-8">
            <SelectedTemplate
              report={report}
              showLogoUpload={showLogoUpload}
              setShowLogoUpload={setShowLogoUpload}
              isUploadingLogo={isUploadingLogo}
              handleLogoUpload={handleLogoUpload}
              handleTitleChange={handleTitleChange}
              updateReport={debouncedUpdateReport}
            />
          </div>
          <Droppable droppableId="questions">
            {(provided) => (
              <div
                className="questions-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {report.questions
                  .sort((a, b) => a.position - b.position)
                  .map((question, index) => (
                    <Draggable
                      key={question.question_id}
                      draggableId={question.question_id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <QuestionItem
                            question={question}
                            report={report}
                            reports={reports}
                            setReport={setReport}
                            onDeleteQuestion={() =>
                              handleDeleteQuestion(question.question_id)
                            }
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {report.questions.length === 0 && (
          <>
            <p className="text-lg font-medium text-gray-700 mb-4">
              Get started by uploading questionare
            </p>
            <div
              className={`mb-4 p-4 border-2 border-dashed rounded-md transition-colors duration-200 ${
                isDragActive
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-300 hover:border-blue-500"
              } ${
                isUploading ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div {...getRootProps()} className="text-center py-10">
                <input {...getInputProps()} />
                {isUploading ? (
                  <div className="space-y-3">
                    <div className="h-12 w-12 mx-auto bg-gray-200 rounded-full animate-pulse"></div>
                    <div className="h-4 w-3/4 mx-auto bg-gray-200 rounded animate-pulse"></div>
                    <div className="h-4 w-1/2 mx-auto bg-gray-200 rounded animate-pulse"></div>
                  </div>
                ) : (
                  <>
                    <AiOutlineFilePdf className="mx-auto h-12 w-12 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" />
                    <p className="mt-2 text-gray-600 text-sm font-medium group-hover:text-blue-600 transition-colors duration-200">
                      {isDragActive
                        ? "Drop the PDF file here"
                        : "Drag and drop a PDF file here, or click to select a file"}
                    </p>
                    <p className="mt-1 text-gray-500 text-xs group-hover:text-blue-500 transition-colors duration-200">
                      Supported file type: PDF
                    </p>
                    <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <AiOutlineUpload className="mr-2" />
                      Select PDF
                    </button>
                  </>
                )}
              </div>
            </div>
            <p className="text-lg font-medium text-gray-700 mb-4">
              Or, start from scratch by adding a question
            </p>
          </>
        )}
        <div>
          <button
            className="flex w-full text-left p-2 bg-transparent hover:bg-gray-100 rounded transition-colors duration-200 text-gray-600 hover:text-gray-800"
            onClick={handleAddQuestion}
          >
            + Add a question
          </button>
        </div>

        <div className="fixed bottom-6 right-6 flex space-x-2">
          <button
            onClick={handleMarkAsCompleted}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={report.completed || isUploading}
          >
            {report.completed ? "Completed" : "Mark as completed"}
          </button>
          <button
            onClick={handleExport}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isUploading}
          >
            Export
          </button>
          <button
            onClick={handleConfigureTemplate}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isUploading}
          >
            Configure Template
          </button>
          <button
            onClick={handlePreview}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isUploading}
          >
            Preview
          </button>
        </div>
      </div>
      {isTemplateModalOpen && (
        <ChooseTemplateModal
          isOpen={isTemplateModalOpen}
          onClose={() => setIsTemplateModalOpen(false)}
          selectedTemplateId={selectedTemplateId}
          onTemplateSelect={handleTemplateSelect}
        />
      )}
    </>
  );
};

export default memo(
  ReportDetails,
  (prevProps, nextProps) => prevProps.reportId === nextProps.reportId
);

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { useUser } from "../../context/userContext";
import { LuArrowLeftToLine } from "react-icons/lu";
import { motion, AnimatePresence } from "framer-motion";
import { CreateProjectModal } from "./components/create-project-modal";
import { MENU_ITEMS } from "../../utils/constants/menuConstants";
import useWorkspaceStore from "../../stores/workspaceStore";
import useProjectStore from "../../stores/projectStore";
import { WorkspaceSelector } from "./components/workspace-selector";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Menubar = () => {
  const location = useLocation();
  const { user } = useUser();
  const {
    selectedWorkspace,
    workspaceUser,
    fetchWorkspaceUser,
    fetchWorkspaceUsers,
  } = useWorkspaceStore();
  const {
    projects,
    selectedProject,
    fetchProjects,
    setSelectedProject,
    isFetchingProjects,
  } = useProjectStore();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
  const [projectSearchQuery, setProjectSearchQuery] = useState("");
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] =
    useState(false);

  const projectMenuRef = useRef(null);
  useOnClickOutside(projectMenuRef, () => setIsProjectMenuOpen(false));

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  useEffect(() => {
    if (user?.data?.id && selectedWorkspace?.workspace_id) {
      fetchWorkspaceUser(selectedWorkspace?.workspace_id, user.data.id);
    }
  }, [user, selectedWorkspace]);
  useEffect(() => {
    if (selectedWorkspace) {
      fetchProjects(selectedWorkspace.workspace_id);
      fetchWorkspaceUsers(selectedWorkspace.workspace_id);
    }
  }, [selectedWorkspace]);

  const handleProjectChange = (project) => {
    setSelectedProject(project);
    setIsProjectMenuOpen(false);
    setProjectSearchQuery("");
    // Animate project transition
    const button = document.querySelector(".project-button");
    if (button) {
      button.classList.add("project-transition");
      setTimeout(() => button.classList.remove("project-transition"), 300);
    }
  };

  const toggleProjectMenu = () => {
    setIsProjectMenuOpen((prev) => !prev);
    if (!isProjectMenuOpen) {
      setTimeout(() => {
        const searchInput = document.querySelector(".project-search");
        if (searchInput) searchInput.focus();
      }, 100);
    }
  };

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(projectSearchQuery.toLowerCase())
  );

  return (
    <motion.div
      className="h-screen text-gray-700 flex flex-col"
      animate={{
        width: isCollapsed ? "64px" : "224px",
        transition: { duration: 0.3, ease: "easeInOut" },
      }}
    >
      <div className="p-4 space-y-4">
        <div className="flex items-center justify-between">
          <AnimatePresence>
            {!isCollapsed && (
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                src="/asset/data/logo.png"
                alt="TIA Studio Logo"
                className="h-12"
              />
            )}
          </AnimatePresence>
          <button
            className="p-1 hover:bg-gray-100 bg-transparent rounded-md"
            onClick={toggleCollapse}
          >
            <motion.div
              animate={{ rotate: isCollapsed ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <LuArrowLeftToLine className="h-4 w-4 text-gray-500" />
            </motion.div>
          </button>
        </div>
        <AnimatePresence>
          <WorkspaceSelector isCollapsed={isCollapsed} />
        </AnimatePresence>
      </div>
      <nav className="flex-grow px-2 py-1 overflow-y-auto">
        <ul className="space-y-0.5">
          {MENU_ITEMS.map((item) => {
            const userRoles =
              user?.data?.roles?.map((role) => role.name.toUpperCase()) || [];
            const normalizedRequiredRoles = item.roles.map((role) =>
              role.toUpperCase()
            );
            const hasPermission =
              workspaceUser.permissions[item.accessIdentifier];

            if (
              userRoles.length > 0 &&
              normalizedRequiredRoles.some((role) =>
                userRoles.includes(role)
              ) &&
              hasPermission
            ) {
              const isActive = location.pathname === item.path;
              return (
                <li
                  key={item.path}
                  className={`mb-2 ${
                    isActive ? "bg-[#e8e8e8] rounded-md" : ""
                  }`}
                >
                  <Link
                    to={item.path}
                    className="flex items-center px-4 py-2 text-sm text-gray-800 hover:bg-[#ebebeb] rounded-md transition-colors duration-200"
                  >
                    <i
                      className={`${item.icon} ${!isCollapsed ? "mr-3" : ""}`}
                    ></i>
                    <AnimatePresence>
                      {!isCollapsed && (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {item.label}
                        </motion.span>
                      )}
                    </AnimatePresence>
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </nav>
      <AnimatePresence>
        {!isCollapsed && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="p-3">
              {/* Project Selector */}
              {selectedWorkspace && (
                <div className="relative mb-4" ref={projectMenuRef}>
                  <div className="flex items-center justify-between mb-1.5">
                    <span className="text-sm font-medium text-gray-500">
                      Projects
                    </span>
                    <button
                      className="text-sm bg-transparent text-gray-500 hover:text-gray-700 flex items-center px-1.5 py-0.5 rounded hover:bg-gray-50"
                      onClick={() => setIsCreateProjectModalOpen(true)}
                    >
                      <i className="pi pi-plus text-[10px] mr-1"></i>
                      New
                    </button>
                  </div>
                  <button
                    onClick={toggleProjectMenu}
                    disabled={isFetchingProjects}
                    className="flex items-center w-full px-2.5 py-1.5 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 rounded-md transition-all duration-200 border border-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  >
                    <div className="flex-1 flex items-center">
                      {isFetchingProjects &&
                      projects.filter(
                        (p) => p.workspace_id === selectedWorkspace.workspace_id
                      ).length === 0 ? (
                        <div className="flex items-center">
                          <i className="pi pi-spinner animate-spin text-gray-400 mr-2 text-sm"></i>
                          <span className="text-gray-500">
                            Loading projects...
                          </span>
                        </div>
                      ) : (
                        <>
                          <div className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></div>
                          <span className="text-left truncate">
                            {selectedProject
                              ? selectedProject.name
                              : "Select Project"}
                          </span>
                        </>
                      )}
                    </div>
                    <i
                      className={`pi ${
                        isProjectMenuOpen ? "pi-chevron-up" : "pi-chevron-down"
                      } ml-1.5 text-gray-400 text-[10px]`}
                    ></i>
                  </button>
                  {isProjectMenuOpen && !isFetchingProjects && (
                    <div className="absolute z-10 bottom-full mb-1 w-full bg-white border border-gray-200 rounded-md shadow-lg overflow-hidden transform transition-all duration-150 ease-out">
                      <div className="p-1.5 border-b border-gray-100">
                        <div className="relative">
                          <i className="pi pi-search absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-[10px]"></i>
                          <input
                            type="text"
                            className="project-search w-full pl-7 pr-2 py-1.5 text-sm bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-200"
                            placeholder="Search projects..."
                            value={projectSearchQuery}
                            onChange={(e) =>
                              setProjectSearchQuery(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="max-h-48 overflow-y-auto">
                        {filteredProjects.length === 0 ? (
                          <div className="px-3 py-2 text-sm text-gray-500 text-center">
                            No projects found
                          </div>
                        ) : (
                          <ul className="py-1">
                            {filteredProjects.map((project) => (
                              <li key={project.project_id}>
                                <button
                                  onClick={() => handleProjectChange(project)}
                                  className={`w-full bg-transparent text-left px-3 py-1.5 text-sm hover:bg-gray-50 transition-colors duration-150 flex items-center justify-between ${
                                    selectedProject?.project_id ===
                                    project.project_id
                                      ? "bg-blue-50 text-blue-600"
                                      : "text-gray-700"
                                  }`}
                                >
                                  <div className="flex items-center">
                                    <div
                                      className={`w-1.5 h-1.5 rounded-full mr-2 ${
                                        selectedProject?.project_id ===
                                        project.project_id
                                          ? "bg-blue-500"
                                          : "bg-gray-300"
                                      }`}
                                    ></div>
                                    <span>{project.name}</span>
                                  </div>
                                  {selectedProject?.project_id ===
                                    project.project_id && (
                                    <i className="pi pi-check text-blue-500 text-[10px]"></i>
                                  )}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="px-3 py-2 text-[10px] text-gray-400">
              TIA Studio v1.0.0
            </div>
            <CreateProjectModal
              isOpen={isCreateProjectModalOpen}
              onClose={() => setIsCreateProjectModalOpen(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
export default Menubar;

import { HiOutlineUserCircle, HiTrash } from "react-icons/hi";
import useWorkspaceStore from "../../../stores/workspaceStore";

const WorkspaceUser = ({ user }) => {
  const { selectedWorkspace, deleteWorkspaceUser } = useWorkspaceStore();

  const handleDelete = async () => {
    try {
      await deleteWorkspaceUser(selectedWorkspace.workspace_id, user.user_id);
    } catch (error) {
      console.error("Failed to delete user:", error);
    }
  };

  return (
    <li className="p-4 hover:bg-gray-50 transition-colors">
      <div className="flex items-center space-x-4">
        <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
          <HiOutlineUserCircle className="h-6 w-6 text-gray-500" />
        </div>
        <div className="flex-1">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm font-medium text-gray-900">{user.name}</p>
              <p className="text-sm text-gray-500">{user.email}</p>
            </div>
            <button
              onClick={handleDelete}
              className="text-red-600 bg-transparent hover:text-red-800 p-2"
              title="Delete user"
            >
              <HiTrash className="h-5 w-5" />
            </button>
          </div>
          <div className="mt-1 flex items-center space-x-2">
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              {user.role}
            </span>
            {user.permission_groups?.map((group, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
              >
                {group}
              </span>
            ))}
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              {user.status}
            </span>
          </div>
          {user.joined_at && (
            <p className="text-xs text-gray-500 mt-1">
              Joined: {new Date(user.joined_at).toLocaleDateString()}
            </p>
          )}
        </div>
      </div>
    </li>
  );
};

export default WorkspaceUser;

import React, { useState } from "react";
import TabLayout from "../layout/dataModelTabLayout";
import "./data.css";
import DataDocuments from "./documents";

const Data = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    // <TabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
    // </TabLayout>
    <DataDocuments />
  );
};

export default Data;

const USER_API_BASE_URL =
  "https://tiaservices.3rdaiautomation.com/ml-world-user/api/v1.0";
const ADMIN_API_BASE_URL =
  "https://tiaservices.3rdaiautomation.com/ml-world-admin/api/v1.0";
const MODEL_API_BASE_URL =
  "https://tiaservices.3rdaiautomation.com/ml-world-model/api/v1.0";
const AWS_API_BASE_URL =
  "https://tiaservices.3rdaiautomation.com/ml-world-aws/api/v1.0";
const PYTHON_API_BASE_URL =
  "https://tiaservices.3rdaiautomation.com/train_model";
const CHATBOT_API_BASE_URL = "https://tiaservices.3rdaiautomation.com/rag";
const REPORT_API_BASE_URL = "https://tiaservices.3rdaiautomation.com/report";
const WORKSPACE_API_BASE_URL =
  "https://tiaservices.3rdaiautomation.com/workspace";

export {
  USER_API_BASE_URL,
  ADMIN_API_BASE_URL,
  MODEL_API_BASE_URL,
  AWS_API_BASE_URL,
  PYTHON_API_BASE_URL,
  CHATBOT_API_BASE_URL,
  REPORT_API_BASE_URL,
  WORKSPACE_API_BASE_URL,
};

export const FolderItem = ({ folder }) => {
  return (
    <li
      key={folder.id}
      className="cursor-pointer p-2 rounded text-sm hover:bg-gray-100"
    >
      <div className="flex items-center">
        <span className="mr-2">📁</span>
        <span>{folder.name}</span>
      </div>
    </li>
  );
};

import { create } from "zustand";
import ReportService from "../service/ReportService";
import useProjectStore from "./projectStore";
import { ERROR_MESSAGES } from "../utils/constants/errorConstants";

const useReportStore = create((set, get) => ({
  // State
  reports: [],
  selectedReport: null,
  isLoading: false,
  error: null,
  isCreatingReport: false,
  reportStatusCounts: null,
  isLoadingStatusCounts: false,
  isStartingQuestionnaire: false,

  // Actions
  fetchReports: async (projectId) => {
    set({ isLoading: true, error: null });
    try {
      const fetchedReports = await ReportService.getProjectReports(projectId);
      const parsedReports = fetchedReports.map((report) => ({
        report_id: report.report_id,
        name: report.name,
        title: report.title,
        isPinned: report.is_pinned,
        archived: report.archived,
        status: report.status,
        logo: report.logo,
        template_id: report.template_id,
        completed: report.completed,
        createdAt: report.created_at,
        updatedAt: report.updated_at,
        questions: report.questions || [],
        metadata: report.metadata || {},
      }));
      set({ reports: parsedReports });
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to fetch reports:", error);
    } finally {
      set({ isLoading: false });
    }
  },

  fetchReportStatusCounts: async (projectId) => {
    set({ isLoadingStatusCounts: true });
    try {
      const statusCounts = await ReportService.getProjectReportsCountByStatus(
        projectId
      );
      set({ reportStatusCounts: statusCounts });
    } catch (error) {
      console.error("Failed to fetch report status counts:", error);
      set({ error: error.message });
    } finally {
      set({ isLoadingStatusCounts: false });
    }
  },

  setSelectedReport: (report) => {
    set({ selectedReport: report });
  },

  createReport: async () => {
    set({ isCreatingReport: true, error: null });
    try {
      const reports = get().reports;
      const projectStore = useProjectStore.getState();
      const projectId = projectStore.selectedProject?.project_id;

      if (!projectId) {
        throw new Error("No project selected");
      }
      // Check if report limit is reached for free version
      if (reports.length >= 5) {
        throw new Error(ERROR_MESSAGES.REPORT_LIMIT_REACHED.message);
      }

      const newReportData = {
        name: `Report name ${reports.length + 1}`,
        title: `Report title ${reports.length + 1}`,
        is_pinned: false,
        project_id: projectId,
        status: "draft",
        report_id: Math.floor(Math.random() * 1000000),
      };
      const createdReport = await ReportService.createReport(newReportData);
      const newReport = {
        report_id: createdReport.report_id,
        name: createdReport.name,
        title: createdReport.title,
        createdAt: createdReport.created_at,
        updatedAt: createdReport.timestamp,
        status: createdReport.status,
        questions: [],
      };
      set((state) => ({
        reports: [...state.reports, newReport],
        selectedReport: newReport,
      }));
      return newReport;
    } catch (error) {
      set({ error: error.message });
      if (error.message === ERROR_MESSAGES.REPORT_LIMIT_REACHED.message) {
        alert(ERROR_MESSAGES.REPORT_LIMIT_REACHED.message);
      }
      console.error("Failed to create report:", error);
    } finally {
      set({ isCreatingReport: false });
    }
  },

  startReportWithQuestionnaire: async (reportId, file) => {
    set({ isStartingQuestionnaire: true, error: null });
    try {
      const response = await ReportService.startReportWithQuestionnaire(
        reportId,
        file
      );

      if (response.acknowledgement !== "success") {
        throw new Error("Failed to start questionnaire");
      }

      const fileDetails = {
        fileName: response.file_details.name,
        fileUrl: response.file_details.url,
        uploadedAt: response.file_details.uploaded_at,
      };

      set((state) => ({
        reports: state.reports.map((report) =>
          report.report_id === reportId ? { ...report, fileDetails } : report
        ),
        selectedReport:
          state.selectedReport?.report_id === reportId
            ? { ...state.selectedReport, fileDetails }
            : state.selectedReport,
      }));

      return fileDetails;
    } catch (error) {
      set({ error: error.message });
      console.error("Failed to start questionnaire:", error);
      throw error;
    } finally {
      set({ isStartingQuestionnaire: false });
    }
  },

  updateReports: (updatedReports) => {
    set({ reports: updatedReports });
  },

  // Reset store
  reset: () => {
    set({
      reports: [],
      selectedReport: null,
      isLoading: false,
      error: null,
      reportStatusCounts: null,
      isLoadingStatusCounts: false,
      isStartingQuestionnaire: false,
    });
  },
}));

export default useReportStore;

export const ERROR_MESSAGES = {
  REPORT_LIMIT_REACHED: {
    title: "Report Limit Reached",
    message:
      "You have reached the maximum limit of 5 reports in the free version. Please upgrade your plan to create more reports.",
    action: "Upgrade Now",
  },
  WORKSPACE_LIMIT_REACHED: {
    title: "Workspace Limit Reached",
    message:
      "You have reached the maximum limit of 2 workspaces in the free version. Please upgrade your plan to create more workspaces.",
    action: "Upgrade Now",
  },
};

import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import WorkspaceService from "../service/WorkspaceService";
import { useUser } from "../context/userContext";
import { ERROR_MESSAGES } from "../utils/constants/errorConstants";

const useWorkspaceStore = create(
  persist(
    (set, get) => ({
      // State
      workspaces: [],
      selectedWorkspace: null,
      workspaceUsers: [],
      isLoading: false,
      error: null,
      isFetchingWorkspaces: false,
      workspaceUser: {
        permissions: {
          trainModel: false,
          runModel: false,
          members: true,
          dataTalk: true,
          reports: true,
          workspaceData: true,
          dashboard: true,
        },
      },
      // Actions
      fetchWorkspaces: async (userId) => {
        set({ error: null, isFetchingWorkspaces: true });
        try {
          const data = await WorkspaceService.getUserWorkspaces(userId);
          set({ workspaces: data });

          // Select most recent workspace if none selected
          if (!get().selectedWorkspace && data.length > 0) {
            const mostRecent = [...data].sort(
              (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
            )[0];
            set({ selectedWorkspace: mostRecent });
          }
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to fetch workspaces:", error);
        } finally {
          set({ isFetchingWorkspaces: false });
        }
      },
      getUserName: (userId) => {
        const state = get();
        if (state.workspaceUsers.length === 0) {
          return "Loading...";
        }
        const user = state.workspaceUsers.find(
          (user) => user.user_id === userId
        );
        return user ? user.name || user.email : "Deleted User";
      },
      fetchWorkspaceUsers: async (workspaceId) => {
        set({ isLoading: true, error: null });
        try {
          const response = await WorkspaceService.getWorkspaceUsers(
            workspaceId
          );
          set({ workspaceUsers: response.data });
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to fetch workspace users:", error);
        } finally {
          set({ isLoading: false });
        }
      },
      fetchWorkspaceUser: async (workspaceId, userId) => {
        set({ isLoading: true, error: null });
        try {
          const response = await WorkspaceService.getWorkspaceUser(
            workspaceId,
            userId
          );
          set({ workspaceUser: response.data });
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to fetch workspace users:", error);
        } finally {
          set({ isLoading: false });
        }
      },

      inviteWorkspaceUser: async (workspaceId, userData) => {
        set({ isLoading: true, error: null });
        try {
          const response = await WorkspaceService.inviteWorkspaceUser(
            workspaceId,
            userData
          );
          set((state) => ({
            workspaceUsers: [...state.workspaceUsers, response.data],
          }));
          return response.data;
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to create workspace user:", error);
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      respondToInvite: async (workspaceId, response) => {
        set({ isLoading: true, error: null });
        try {
          const result = await WorkspaceService.respondToWorkspaceInvite(
            workspaceId,
            {
              response: response, // 'accept' or 'reject'
            }
          );

          // If accepted, update workspaces list
          if (response === "accept") {
            const workspaces = await WorkspaceService.getUserWorkspaces();
            set({ workspaces });
          }

          return result;
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to respond to workspace invite:", error);
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      deleteWorkspaceUser: async (workspaceId, userId) => {
        set({ isLoading: true, error: null });
        try {
          await WorkspaceService.deleteWorkspaceUser(workspaceId, userId);
          set((state) => {
            const remainingWorkspaces = state.workspaces.filter(
              (w) => w.workspace_id !== workspaceId
            );
            const selectedWorkspaceExists = remainingWorkspaces.some(
              (workspace) => workspace.workspace_id === state.selectedWorkspace?.workspace_id
            );

            return {
              workspaces: remainingWorkspaces,
              selectedWorkspace: selectedWorkspaceExists
                ? state.selectedWorkspace
                : remainingWorkspaces[0] || null,
              workspaceUsers: state.workspaceUsers.filter(
                (user) => user.user_id !== userId
              ),
            };
          });
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to delete workspace user:", error);
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      setSelectedWorkspace: (workspace) => {
        set({ selectedWorkspace: workspace });
      },

      createWorkspace: async (workspaceData) => {
        set({ isLoading: true, error: null });
        const workspaces = get().workspaces;

        try {
          if (workspaces.length >= 2) {
            throw new Error(ERROR_MESSAGES.WORKSPACE_LIMIT_REACHED.message);
          }
          const response = await WorkspaceService.createWorkspace(
            workspaceData
          );
          const newWorkspace = response.data.workspace;
          set((state) => ({
            workspaces: [...state.workspaces, newWorkspace],
            selectedWorkspace: newWorkspace,
          }));
          return newWorkspace;
        } catch (error) {
          set({ error: error.message });
          if (
            error.message === ERROR_MESSAGES.WORKSPACE_LIMIT_REACHED.message
          ) {
            alert(ERROR_MESSAGES.WORKSPACE_LIMIT_REACHED.message);
          }
          console.error("Failed to create workspace:", error);
        } finally {
          set({ isLoading: false });
        }
      },

      deleteWorkspaces: async (workspaceIds) => {
        set({ isLoading: true, error: null });
        try {
          await WorkspaceService.deleteWorkspaces(workspaceIds);
          set((state) => {
            const remainingWorkspaces = state.workspaces.filter(
              (w) => !workspaceIds.includes(w.workspace_id)
            );
            
            // Check if selected workspace was deleted
            const wasSelectedWorkspaceDeleted = state.selectedWorkspace && 
              workspaceIds.includes(state.selectedWorkspace.workspace_id);

            return {
              workspaces: remainingWorkspaces,
              // If selected workspace was deleted, select first available workspace or null
              selectedWorkspace: wasSelectedWorkspaceDeleted
                ? (remainingWorkspaces[0] || null)
                : state.selectedWorkspace
            };
          });
        } catch (error) {
          set({ error: error.message });
          console.error("Failed to delete workspace:", error);
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      // Reset store
      reset: () => {
        set({
          workspaces: [],
          selectedWorkspace: null,
          workspaceUsers: [],
          isLoading: false,
          error: null,
        });
      },
    }),

    {
      name: "workspace-storage", // unique name for this store
      storage: createJSONStorage(() => localStorage),
      // Only persist these specific fields
      partialize: (state) => ({
        workspaces: state.workspaces,
        selectedWorkspace: state.selectedWorkspace,
        workspaceUser: state.workspaceUser,
      }),
    }
  )
);

export default useWorkspaceStore;
